import React, { useCallback, useEffect, useState } from 'react';
import './css/index.scss';
import { useDispatch, useSelector } from 'react-redux';
import PageTitle from '../../components/PageTitle';
import MyTable from '../../components/MyTable';
import useInputState from '../../helpers/hooks/useInputState';
import { ADMIN_HEADER_COLUMNS, ADMIN_MODAL_STATES, ADMIN_WIDTHS } from './constants';
import AdminItem from './components/AdminItem';
import AdminModal from './components/AdminModal';
import { getAdminsRequest } from '../../store/actions/admins';
import MyButton from '../../components/MyButton';
import addAdminIcon from '../../assets/formIcons/addAdmin.svg';

function Admins() {
  const dispatch = useDispatch();
  const {
    admins, adminsTotal, adminsLoading, adminsError,
  } = useSelector((store) => store.admins);
  const [page, setPage] = useInputState(1);
  const [perPage, setPerPage] = useState(25);
  const [q, setQ] = useInputState();
  const [isInit, setIsInit] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalState, setModalState] = useState('');
  const [selectedAdmin, setSelectedAdmin] = useState({});

  const getAdmins = ({ paramsPage, paramsPerPage = perPage, isInitRequired = true }) => {
    if (adminsLoading || (isInitRequired && !isInit)) return;
    dispatch(getAdminsRequest({
      q,
      page: paramsPage,
      per_page: paramsPerPage,
    }));
  };

  const TableItemComponent = useCallback((item, index) => (
    <AdminItem
      key={item._id}
      item={item}
      widths={ADMIN_WIDTHS}
      index={index}
      setSelectedAdmin={setSelectedAdmin}
      setModalState={setModalState}
      setIsModalVisible={setIsModalVisible}
    />
  ), []);

  const handlePage = (newVal) => {
    if (newVal === page) return;
    setPage(newVal);
    getAdmins({ paramsPage: newVal });
  };

  const handleAddAdministrator = () => {
    setModalState(ADMIN_MODAL_STATES.first);
    setSelectedAdmin({});
    setIsModalVisible(true);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setPage(1);

      if (perPage < 1) {
        setPerPage(1);
        getAdmins({ paramsPage: 1, paramsPerPage: 1 });
      } else if (perPage > 500) {
        setPerPage(500);
        getAdmins({ paramsPage: 1, paramsPerPage: 500 });
      } else {
        getAdmins({ paramsPage: 1 });
      }
    }, 600);

    return () => clearTimeout(timer);
  }, [perPage]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setPage(1);
      getAdmins({ paramsPage: 1 });
    }, 400);

    return () => clearTimeout(timer);
  }, [q]);

  useEffect(() => {
    getAdmins({ paramsPage: page, isInitRequired: false });
    setTimeout(() => {
      setIsInit(true);
    }, 500);
  }, []);

  return (
    <div>
      <PageTitle
        text="Администраторы"
        RightComponent={(
          <MyButton
            text="Добавить"
            svgPath={addAdminIcon}
            onClick={handleAddAdministrator}
            className="admin_table_add_btn"
          />
        )}
      />
      <MyTable
        q={q}
        setQ={setQ}
        perPage={perPage}
        setPerPage={setPerPage}
        headerColumns={ADMIN_HEADER_COLUMNS}
        Item={TableItemComponent}
        data={admins}
        page={page}
        setPage={handlePage}
        total={adminsTotal}
        isSecondaryStyle
      />
      <AdminModal
        selectedAdmin={selectedAdmin}
        modalState={modalState}
        setModalState={setModalState}
        isVisible={isModalVisible}
        setIsVisible={setIsModalVisible}
        setSelectedAdmin={setSelectedAdmin}
      />
    </div>
  );
}

export default Admins;
