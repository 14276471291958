import React, { useState } from 'react';
import './css/index.scss';
import moment from 'moment';
import MyButton from '../../../../components/MyButton';
import Api from '../../../../Api';
import { REPORT_MODAL_STATES } from '../../constants';
import ReportsModal from '../ReportsModal';

function ReportInfos({ complaints, updateComplaints }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalState, setModalState] = useState('');
  const [complaintId, setComplaintId] = useState(null);
  const [selectedPhoto, setSelectedPhoto] = useState({});

  const onApproveClick = (complaint) => {
    if (complaint && complaint._id) {
      setModalState(REPORT_MODAL_STATES.decline);
      setIsModalVisible(true);
      setComplaintId(complaint._id);
    } else {
      console.error('Invalid complaint object:', complaint);
    }
  };

  const onPhotoItemClick = (photo, complaint) => () => {
    setSelectedPhoto({ url: photo });
    setModalState(REPORT_MODAL_STATES.viewPhoto);
    setIsModalVisible(true);
    setComplaintId(complaint);
  };

  return (
    <>
      <ReportsModal
        isVisible={isModalVisible}
        setIsVisible={setIsModalVisible}
        modalState={modalState}
        selectedPhoto={selectedPhoto}
        setSelectedPhoto={setSelectedPhoto}
        complaintId={complaintId}
        updateComplaints={updateComplaints}
        isOrganizationOrInfo
        modalApproveInfoText="Вы уверены, что хотите пометить информацию как прочитанное?"
      />
      {complaints.map((complaint, index) => (
        <div key={complaint._id} className="report_infos">
          <div className="report_item_review">
            <p className="report_item_review_description">{complaint.text}</p>
            <div className="report_item_review_gallery">
              {complaint.photos.map((photoId, i) => (
                <img
                  key={i}
                  src={`${Api.url}/${photoId.preview_url}`}
                  alt=""
                  className="report_item_review_gallery_image"
                  onClick={onPhotoItemClick(photoId.url, complaint)}
                />
              ))}
            </div>
          </div>
          <div className="report_item_info">
            <div className="report_item_info_block">
              <div className="report_item_info_block_first">
                <p className="report_item_info_title">{complaint.type === 'wrong' ? 'Недостоверная информация' : 'Оскорбительная информация'}</p>
                <p className="report_item_info_subtitle">Причина</p>
              </div>
              <div>
                <p className="report_item_info_title">
                  {moment(complaint.createdAt).format('DD/MM/YYYY')}
                </p>
                <p className="report_item_info_subtitle">Дата</p>
              </div>
            </div>
            <div>
              <p className="report_item_info_title">
                {complaint.subject && complaint.subject.information_item_id && complaint.subject.information_item_id.name}
              </p>

              <p className="report_item_info_subtitle">
                Статья
              </p>
            </div>
          </div>
          <div className="report_buttons">
            <MyButton
              text="Ок"
              className="report_buttons_secondary"
              onClick={() => onApproveClick(complaint)}
            />
          </div>
        </div>
      ))}
    </>
  );
}

export default ReportInfos;
