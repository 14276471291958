export const GET_COMPLAINTS_REQUEST = 'GET_COMPLAINTS_REQUEST';
export const GET_COMPLAINTS_SUCCESS = 'GET_COMPLAINTS_SUCCESS';
export const GET_COMPLAINTS_FAIL = 'GET_COMPLAINTS_FAIL';

export const getComplaintsRequest = (payload) => ({
  type: GET_COMPLAINTS_REQUEST,
  payload,
});

export const getComplaintsSuccess = (data) => ({
  type: GET_COMPLAINTS_SUCCESS,
  payload: data,
});

export const getComplaintsFail = (error) => ({
  type: GET_COMPLAINTS_FAIL,
  payload: error,
});

export const GET_COMPLAINTS_COUNT_REQUEST = 'GET_COMPLAINTS_COUNT_REQUEST';
export const GET_COMPLAINTS_COUNT_SUCCESS = 'GET_COMPLAINTS_COUNT_SUCCESS';
export const GET_COMPLAINTS_COUNT_FAIL = 'GET_COMPLAINTS_COUNT_FAIL';

export const getComplaintsCountRequest = (payload) => ({
  type: GET_COMPLAINTS_COUNT_REQUEST,
  payload,
});

export const getComplaintsCountSuccess = (data) => ({
  type: GET_COMPLAINTS_COUNT_SUCCESS,
  payload: data,
});

export const getComplaintsCountFail = (error) => ({
  type: GET_COMPLAINTS_COUNT_FAIL,
  payload: error,
});

export const UPDATE_COMPLAINTS = 'UPDATE_COMPLAINTS';

export const updateComplaints = (complaintId, action) => ({
  type: UPDATE_COMPLAINTS,
  payload: {
    complaintId,
    action,
  },
});

export const UPDATE_COMPLAINT_COUNT = 'UPDATE_COMPLAINT_COUNT';

export const updateComplaintCount = (filterId) => ({
  type: UPDATE_COMPLAINT_COUNT,
  payload: {
    filterId,
  },
});
