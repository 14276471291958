import React from 'react';
import './css/index.scss';
import IconButton from '../../../../components/IconButton';
import DeleteIcon from '../../../../assets/formIcons/delete.svg';
import EditIcon from '../../../../assets/formIcons/editArticle.svg';
import ViewIcon from '../../../../assets/formIcons/viewArticle.svg';
import CancelEditingIcon from '../../../../assets/formIcons/cancelEditing.svg';
import SaveIcon from '../../../../assets/formIcons/save.svg';
import { ARTICLES_PAGE_STATES } from '../../constants';
import CopyTag from '../../../../components/CopyTag';
import { HTML_TAGS } from '../../../../helpers/constants';

function ArticlesButtons({
  pageState, setPageState, onDeleteClick, onInnerHtmlSave, onCancelClick, savingLoading,
}) {
  const onEditClick = () => {
    if (pageState === ARTICLES_PAGE_STATES.editing) {
      setPageState(ARTICLES_PAGE_STATES.preview);
    } else {
      setPageState(ARTICLES_PAGE_STATES.editing);
    }
  };

  return (
    <div className="articles_buttons_wrapper">
      <div className="buttons_row">
        {pageState !== ARTICLES_PAGE_STATES.view && (
          <IconButton
            svgPath={CancelEditingIcon}
            wrapperClassName="article_button"
            tooltipText="Отменить"
            onClick={onCancelClick}
          />
        )}
        <IconButton
          svgPath={DeleteIcon}
          wrapperClassName="article_button"
          tooltipText="Удалить"
          onClick={onDeleteClick}
        />
        <IconButton
          svgPath={pageState === ARTICLES_PAGE_STATES.editing ? ViewIcon : EditIcon}
          wrapperClassName="article_button"
          tooltipText={pageState === ARTICLES_PAGE_STATES.editing ? 'Предпросмотр' : 'Редактировать'}
          onClick={onEditClick}
        />
        {pageState !== ARTICLES_PAGE_STATES.view && (
          <IconButton
            svgPath={SaveIcon}
            wrapperClassName="article_button"
            tooltipText="Сохранить"
            onClick={onInnerHtmlSave}
            loading={savingLoading}
          />
        )}
      </div>

      {pageState === ARTICLES_PAGE_STATES.editing && (
      <div className="article_tags_list">
        {HTML_TAGS.map((tag, index) => <CopyTag key={index} {...tag} />)}
      </div>
      )}
    </div>
  );
}

export default ArticlesButtons;
