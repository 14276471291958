import ArticlesIcon from '../assets/sidebarIcons/articles.svg';
import ArticlesBlueIcon from '../assets/sidebarIcons/articlesBlue.svg';
import GalleryIcon from '../assets/sidebarIcons/gallery.svg';
import GalleryBlueIcon from '../assets/sidebarIcons/galleryBlue.svg';
import ReportsIcon from '../assets/sidebarIcons/reports.svg';
import ReportsBlueIcon from '../assets/sidebarIcons/reportsBlue.svg';
import UsersIcon from '../assets/sidebarIcons/users.svg';
import UsersBlueIcon from '../assets/sidebarIcons/usersBlue.svg';
import OrganizationsIcon from '../assets/sidebarIcons/organizations.svg';
import OrganizationsBlueIcon from '../assets/sidebarIcons/organizationsBlue.svg';
import AdminsIcon from '../assets/sidebarIcons/admins.svg';
import AdminsBlueIcon from '../assets/sidebarIcons/adminsBlue.svg';
import CitiesIcon from '../assets/sidebarIcons/cities.svg';
import CitiesBlueIcon from '../assets/sidebarIcons/citiesBlue.svg';
import Articles from '../pages/Articles';
import Gallery from '../pages/Gallery';
import Reports from '../pages/Reports';
import Users from '../pages/Users';
import Organizations from '../pages/Organizations';
import Admins from '../pages/Admins';
import Cities from '../pages/Cities';
import AddOrganization from '../pages/AddOrganization';
import EditOrganizationPhotos from '../pages/EditOrganizationPhotos';

export const SidebarData = {
  cities: {
    title: 'Города',
    path: '/cities',
    icon: CitiesIcon,
    selectedIcon: CitiesBlueIcon,
    component: <Cities />,
  },
  articles: {
    title: 'Статьи',
    path: '/articles',
    icon: ArticlesIcon,
    selectedIcon: ArticlesBlueIcon,
    component: <Articles />,
  },
  gallery: {
    title: 'Галерея',
    path: '/gallery',
    icon: GalleryIcon,
    selectedIcon: GalleryBlueIcon,
    component: <Gallery />,
    target: '_blank',
  },

  reports: {
    title: 'Репорты',
    path: '/reports',
    icon: ReportsIcon,
    selectedIcon: ReportsBlueIcon,
    component: <Reports />,
  },
  users: {
    title: 'Пользователи',
    path: '/users',
    icon: UsersIcon,
    selectedIcon: UsersBlueIcon,
    component: <Users />,
  },
  organizations: {
    title: 'Организации',
    path: '/organizations',
    pathPrefixes: [
      { path: '/view', component: <Organizations /> },
      { path: '/create', component: <AddOrganization /> },
      { path: '/edit', component: <AddOrganization /> },
      { path: '/edit-photos', component: <EditOrganizationPhotos /> },
    ],
    icon: OrganizationsIcon,
    selectedIcon: OrganizationsBlueIcon,
  },
  admins: {
    title: 'Администраторы',
    path: '/admins',
    icon: AdminsIcon,
    selectedIcon: AdminsBlueIcon,
    component: <Admins />,
  },
};
