import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  SET_TOKEN,
  SET_PROFILE,
  SET_ALLOCATED_COUNTRIES,
  SET_SYSTEM_COUNTRY, GET_PROFILE_SUCCESS,
} from '../actions/account';

const initialState = {
  token: '',
  loginLoading: false,
  loginError: '',
  profile: {},

  allocatedCountries: [],
  systemCountry: {},
};
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_REQUEST: {
      return {
        ...state,
        loginLoading: true,
        loginError: {},
      };
    }
    case LOGIN_SUCCESS: {
      return {
        ...state,
        token: action.payload.token,
        profile: action.payload.profile,
        loginLoading: false,
        loginError: '',
      };
    }
    case LOGIN_FAILURE: {
      return {
        ...state,
        loginError: action?.payload || '',
        loginLoading: false,
      };
    }
    case GET_PROFILE_SUCCESS: {
      return {
        ...state,
        profile: action.payload.profile,
      };
    }

    case SET_TOKEN: {
      return {
        ...state,
        token: action.payload,
      };
    }

    case SET_PROFILE: {
      return {
        ...state,
        profile: action.payload,
      };
    }

    case SET_ALLOCATED_COUNTRIES: {
      return {
        ...state,
        allocatedCountries: action.payload,
      };
    }

    case SET_SYSTEM_COUNTRY: {
      return {
        ...state,
        systemCountry: action.payload,
      };
    }

    case LOGOUT: {
      return {
        ...initialState,
      };
    }

    default: {
      return state;
    }
  }
}
