import React, { useCallback, useState } from 'react';
import './css/index.scss';
import OrgDropDownIcon from '../../assets/formIcons/orgDropDown.svg';

function CustomTimeInput({
  label, value = '', componentStateToSet, setTempComponentState, isActive,
}) {
  const handleClick = useCallback(() => {
    if (isActive) {
      setTempComponentState(null);
    } else {
      setTempComponentState(componentStateToSet);
    }
  }, [componentStateToSet, setTempComponentState, isActive]);

  return (
    <div
      className={`input_field ${isActive && 'is_active'}`}
      onClick={handleClick}
    >
      <input
        placeholder={label}
        value={value}
        type="text"
        className="input_field_input"
        readOnly
      />
      <img src={OrgDropDownIcon} alt="" className="dropdown_icon" />
    </div>
  );
}

export default CustomTimeInput;
