import React from 'react';
import './css/index.scss';
import TableCell from '../../../../components/TableCell';
import DotsIcon from '../../../../assets/formIcons/dots.svg';
import BlockIcon from '../../../../assets/formIcons/block.svg';
import ChangeIcon from '../../../../assets/formIcons/change.svg';
import TableRow from '../../../../components/TableRow';
import useComponentVisible from '../../../../helpers/hooks/useComponentVisible';
import { ADMIN_MODAL_STATES } from '../../constants';
import PasswordServices from '../../../../helpers/PasswordServices';
import Api from '../../../../Api';

function AdminItem({
  item, widths, index, setSelectedAdmin, setModalState, setIsModalVisible,
}) {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const onPopupItemClick = (state) => () => {
    setSelectedAdmin(item);
    setModalState(state);
    setIsModalVisible(true);
    setIsComponentVisible(false);
  };

  return (
    <TableRow key={item._id}>
      <TableCell width={widths[0]}>
        {item.view_complains_countries.length || item.change_info_countries.length ? (
          item.view_complains_countries.length
            ? item.view_complains_countries
            : item.change_info_countries)
          .map((country) => <img className="admin_item_country_flag" key={country._id} src={Api.url + country.flag_url} alt="" />) : '───'}
      </TableCell>
      <TableCell width={widths[1]}>{item.display_name}</TableCell>
      <TableCell width={widths[2]}>{item.email}</TableCell>
      <TableCell width={widths[3]}>{PasswordServices.decryptPassword(item.password)}</TableCell>
      <TableCell width={widths[4]}>{item._id}</TableCell>
      <TableCell
        width={widths[5]}
        isIcon
        onClick={() => setIsComponentVisible(true)}
      >
        <img src={DotsIcon} alt="" />
      </TableCell>

      <div ref={ref} className={`table_popup ${isComponentVisible && 'visible'}`}>

        <div className="table_popup_item" onClick={onPopupItemClick(ADMIN_MODAL_STATES.delete)}>
          <img src={BlockIcon} alt="" />
          <p>Удалить админа</p>
        </div>

        <div className="table_popup_item" onClick={onPopupItemClick(ADMIN_MODAL_STATES.first)}>
          <img src={ChangeIcon} alt="" />
          <p>Изменить данные</p>
        </div>
      </div>
    </TableRow>
  );
}

export default AdminItem;
