export const REPORT_FILTERS = [
  { id: 'review', name: 'Комментарии' },
  { id: 'photo', name: 'Фотографии' },
  { id: 'info', name: 'Информация' },
  { id: 'organization', name: 'Собственники' },
];

export const REPORT_MODAL_STATES = {
  decline: 'decline',
  viewPhoto: 'viewPhoto',
  approve: 'approve',
};
