import React from 'react';
import './css/index.scss';

function MyButton({
  loading,
  onClick,
  text,
  svgPath,
  className = '',
  textClassName = '',
  iconClassName = '',
  loadingEqualsDisabled = true,
  disabled,
  secondary,
}) {
  return (
    <div
      onClick={!loading && !disabled ? onClick : undefined}
      className={`${className} ${((loading && loadingEqualsDisabled) || disabled) && 'disabled_button'} button_div ${secondary && 'secondary'}`}
    >
      {svgPath && <img src={svgPath} alt="" className={`${iconClassName} ${loading && 'disabled'} button_icon`} />}
      {text && <p className={`${textClassName} ${loading && 'disabled'} button_text`}>{text}</p>}
    </div>
  );
}

export default MyButton;
