import React, { useRef, useState } from 'react';
import MyButton from '../../../components/MyButton';
import AddPhotosIcon from '../../../assets/formIcons/addPhotosDark.svg';
import SaveIcon from '../../../assets/formIcons/saveDark.svg';
import DeletePhotoIcon from '../../../assets/formIcons/deletePhoto.svg';
import IconButton from '../../../components/IconButton';
import Api from '../../../Api';
import MyModal from '../../../components/MyModal';
import { PHOTOS_COUNT } from '../../../helpers/constants';
import getFormData from '../../../helpers/getFormData';
import sendRequest from '../../../helpers/sendRequest';

const ModalStates = { delete: 'delete', error: 'error', success: 'success' };

function PhotosPart({
  savedPhotos,
  ownerId,
  initData,
  organizationId,
}) {
  const selectPhotosInput = useRef();

  const [selected, setSelected] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalText, setModalText] = useState('');
  const [modalState, setModalState] = useState('');
  const [loading, setLoading] = useState(false);

  const [selectedPhoto, setSelectedPhoto] = useState({});

  const onAddClick = () => {
    if (typeof selectPhotosInput.current?.click === 'function') {
      selectPhotosInput.current.click();
    }
  };
  const onPhotosSelect = async (ev) => {
    const { files: filesList } = ev.target;
    const files = Array.from(filesList);
    if (!filesList.length) return;

    const convertedFiles = files.map((item) => ({
      file: item,
      id: Math.random(),
      uri: URL.createObjectURL(item),
    }));

    const newSelectedLength = [...convertedFiles, ...selected].length;
    const savingCount = PHOTOS_COUNT.carouselMax - savedPhotos.length;
    if (savedPhotos.length + newSelectedLength > PHOTOS_COUNT.carouselMax) {
      setModalState(ModalStates.error);
      setModalText(
        `Максимальное количество фото на карусели: 15. 
      У вас уже было ${savedPhotos.length} и вы выбрали ещё ${newSelectedLength}.
      Сохранятся только последние ${savingCount}`,
      );
      setIsModalVisible(true);
    }

    setSelected((prev) => [...convertedFiles, ...prev].slice(0, savingCount));
  };

  const onSaveClick = async () => {
    if (!selected.length) {
      return;
    }
    const formData = getFormData({ type: 'carousel', owner_id: ownerId }, selected);
    const data = await sendRequest({
      request: Api.addOrganizationPhotos,
      payload: { id: organizationId, formData },
      warnErrorText: 'while adding photos',
      setLoading,
    });

    let modalStateTmp = ModalStates.success;
    let modalTextTmp = `${selected.length} фото успешно сохранилось!`;
    if (data?.status !== 'ok') {
      modalStateTmp = ModalStates.error;
      modalTextTmp = data?.message || 'Server error';
    }
    setModalState(modalStateTmp);
    setModalText(modalTextTmp);
    setIsModalVisible(true);
    if (data?.status !== 'ok') {
      return;
    }
    setSelected([]);
    await initData();
  };

  const onDeleteClick = (photo) => () => {
    setSelected((prev) => prev.filter((item) => item.id !== photo.id));
  };

  const onDeleteSavedClick = (photo) => () => {
    if (savedPhotos.length <= PHOTOS_COUNT.carouselMin) {
      setModalState(ModalStates.error);
      setModalText('Минимальное количество фото на карусели: 5');
      setIsModalVisible(true);
    }

    setModalState(ModalStates.delete);
    setSelectedPhoto(photo);
    setIsModalVisible(true);
  };

  const deletePhoto = async () => {
    if (!selectedPhoto?._id) return;
    const data = await sendRequest({
      request: Api.removeOrganizationPhotos,
      payload: { id: organizationId, payload: { photos_to_delete: [selectedPhoto._id], type: 'carousel' } },
      warnErrorText: 'while removing photos',
      setLoading,
    });

    let modalStateTmp = ModalStates.success;
    let modalTextTmp = 'Фото успешно удалилось!';
    if (data?.status !== 'ok') {
      modalStateTmp = ModalStates.error;
      modalTextTmp = data?.message || 'Server error';
    }
    setModalState(modalStateTmp);
    setModalText(modalTextTmp);
    if (data?.status !== 'ok') {

    }

    await initData();
  };

  const closeModal = () => {
    setIsModalVisible(false);
    setTimeout(() => {
      setModalState('');
      if (selectedPhoto) {
        setSelectedPhoto({});
      }
      setModalText('');
    }, 400);
  };

  return (
    <div className="photos_container">
      <div className="photos_container_header">
        <p className="photos_container_title">Новые</p>
        <div className="photos_container_header_right">
          <IconButton
            svgPath={AddPhotosIcon}
            onClick={onAddClick}
            wrapperClassName="photos_container_header_btn"
          />
          <IconButton
            svgPath={SaveIcon}
            onClick={onSaveClick}
            loading={loading || !selected.length}
            wrapperClassName="photos_container_header_btn"
          />
        </div>
      </div>

      <div>
        <input
          ref={selectPhotosInput}
          type="file"
          multiple
          accept="image/png, image/jpeg"
          className="photos_container_select_photos"
          onChange={onPhotosSelect}
        />

        <div className="photos_container_photos">
          {selected.length ? (
            selected.map((photo) => (
              <div
                key={photo.id}
                className="photos_container_photos_item"
              >
                <img
                  src={photo.uri}
                  alt=""
                  className="photos_container_photos_item_photo"
                />

                <div className="photos_container_photos_item_overlay">
                  <div
                    className="photos_container_photos_item_overlay_icon"
                    onClick={onDeleteClick(photo)}
                  >
                    <img src={DeletePhotoIcon} alt="" />
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p className="photos_container_photos_no_photo">Нет выбранных фото</p>
          )}
        </div>
      </div>

      <div className="photos_container_header second">
        <p className="photos_container_title">Сохраненные</p>
      </div>
      <div>
        <div className="photos_container_photos">
          {savedPhotos.length ? (
            savedPhotos.map((photo) => (
              <div
                key={photo._id}
                className="photos_container_photos_item"
              >
                <img
                  src={Api.url + photo.preview_url}
                  alt=""
                  className="photos_container_photos_item_photo"
                />

                <div className="photos_container_photos_item_overlay">
                  <div
                    className="photos_container_photos_item_overlay_icon"
                    onClick={onDeleteSavedClick(photo)}
                  >
                    <img src={DeletePhotoIcon} alt="" />
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p className="photos_container_photos_no_photo">Нет сохранённых фото</p>
          )}
        </div>
      </div>

      <MyModal isOpen={isModalVisible} closeFunction={closeModal}>
        {modalState === ModalStates.error
          ? (
            <div className="edit_organization_photos_modal">
              <p className="modal_title">Ошибка</p>

              <p className="modal_info">{modalText}</p>

              <div className="modal_buttons_row one_button">
                <MyButton
                  className="edit_organization_photos_modal_button"
                  text="Ок"
                  onClick={closeModal}
                />
              </div>
            </div>
          )
          : modalState === ModalStates.success
            ? (
              <div className="edit_organization_photos_modal">
                <p className="modal_title">Успешно</p>

                <p className="modal_info">{modalText}</p>

                <div className="modal_buttons_row one_button">
                  <MyButton
                    className="edit_organization_photos_modal_button"
                    text="Ок"
                    onClick={closeModal}
                  />
                </div>
              </div>
            ) : (
              <div className="edit_organization_photos_modal">
                <p className="modal_title">Удалить?</p>

                <img className="modal_photo" src={Api.url + (selectedPhoto?.preview_url || '')} alt="" />

                <p className="modal_info">
                  Вы уверены что хотите удалить эту фотографию? Отменить данное
                  действие будет невозможно.
                </p>

                <div className="modal_buttons_row">
                  <MyButton className="modal_button" text="Отмена" secondary onClick={closeModal} />
                  <MyButton
                    className="modal_button"
                    text="Удалить"
                    loading={loading}
                    onClick={deletePhoto}
                  />
                </div>
              </div>
            )}
      </MyModal>
    </div>
  );
}

export default PhotosPart;
