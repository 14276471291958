import React, { useState } from 'react';
import './css/index.scss';
import { useDispatch, useSelector } from 'react-redux';
import MyModal from '../../../../components/MyModal';
import MyButton from '../../../../components/MyButton';
import { REPORT_MODAL_STATES } from '../../constants';
import Api from '../../../../Api';
import sendRequest from '../../../../helpers/sendRequest';
import { getComplaintsCountRequest } from '../../../../store/actions/reports';

function ReportsModal({
  isVisible,
  setIsVisible,
  selectedPhoto,
  setSelectedPhoto,
  modalState,
  complaintId,
  updateComplaints,
  modalApproveInfoText,
  modalDeleteInfoText,
  isOrganizationOrInfo,
  subjectType,
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { systemCountry } = useSelector((store) => store.account);

  const closeModal = () => {
    if (loading) {
      return;
    }
    setIsVisible(false);
    setTimeout(() => {
      setSelectedPhoto({});
    }, 400);
  };

  const answerComplainClick = async (answer, complaint) => {
    const payload = {
      country_id: systemCountry._id,
      answer,
      complain_id: complaint,
    };
    const data = await sendRequest({
      request: Api.answerComplain,
      payload,
      warnErrorText: 'while answering complaint',
      setLoading,
    });

    dispatch(getComplaintsCountRequest({ systemCountry }));

    if (data?.status === 'ok') {
      updateComplaints(complaint, answer, subjectType);
      closeModal();
    }
  };

  const onDeclineClick = async () => {
    if (modalState === REPORT_MODAL_STATES.decline) {
      await answerComplainClick('decline', complaintId);
    }
  };

  const onApproveClick = async () => {
    await answerComplainClick('approve', complaintId);
  };

  return (
    <MyModal
      isOpen={isVisible}
      closeFunction={closeModal}
      style={
                modalState === REPORT_MODAL_STATES.viewPhoto
                  ? {
                    padding: 0,
                    borderRadius: 0,
                    borderWidth: 0,

                  }
                  : {}
            }
    >
      {modalState === REPORT_MODAL_STATES.approve ? (
        <div className="reports_modal">
          <p className="modal_title">Удалить?</p>
          <p className="modal_info">
            {modalDeleteInfoText
                            || 'Вы уверены что хотите удалить эту информацию? Отменить данное действие будет невозможно.'}
          </p>
          <div className="modal_buttons_row">
            <MyButton
              className="modal_button"
              text="Отмена"
              secondary
              onClick={closeModal}
            />
            <MyButton
              className="modal_button"
              text="Удалить"
              loading={loading}
              onClick={onApproveClick}
            />
          </div>
        </div>
      ) : modalState === REPORT_MODAL_STATES.decline && !isOrganizationOrInfo ? (
        <div className="reports_modal">
          <p className="modal_title">Оставить?</p>
          <p className="modal_info">
            {modalApproveInfoText || 'Вы уверены что хотите оставить эту информацию?'}
          </p>
          <div className="modal_buttons_row">
            <MyButton
              className="modal_button"
              text="Отмена"
              secondary
              onClick={closeModal}
            />
            <MyButton
              className="modal_button"
              text="Оставить"
              loading={loading}
              onClick={onDeclineClick}
            />
          </div>
        </div>
      ) : modalState === REPORT_MODAL_STATES.viewPhoto ? (
        <div className="reports_modal_big">
          <img
            className="modal_photo_big"
            src={Api.url + selectedPhoto.url}
            alt=""
          />
        </div>
      ) : isOrganizationOrInfo ? (
        <div className="reports_modal">
          <p className="modal_info">
            {modalApproveInfoText}
          </p>
          <div className="modal_buttons_row">
            <MyButton
              className="modal_button"
              text="Отмена"
              secondary
              onClick={closeModal}
            />
            <MyButton
              className="modal_button"
              text="Ок"
              loading={loading}
              onClick={onApproveClick}
            />
          </div>
        </div>
      ) : null}
    </MyModal>
  );
}

export default ReportsModal;
