import React from 'react';
import './css/index.scss';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TableCell from '../../../../components/TableCell';
import DotsIcon from '../../../../assets/formIcons/dots.svg';
import BlockIcon from '../../../../assets/formIcons/block.svg';
import RefreshIcon from '../../../../assets/formIcons/refresh.svg';
import ChangeUserIcon from '../../../../assets/formIcons/changeUser.svg';
import ChangeOrgIcon from '../../../../assets/formIcons/changeOrg.svg';
import ChangePhotosIcon from '../../../../assets/formIcons/photosSmall.svg';
import TableRow from '../../../../components/TableRow';
import useComponentVisible from '../../../../helpers/hooks/useComponentVisible';
import { ORGANIZATION_MODAL_STATES } from '../../constants';

function OrganizationItem({
  item, itemsPassed, widths, index, setSelectedOrganization, setModalState, setIsModalVisible,
}) {
  const navigate = useNavigate();
  const { profile } = useSelector((store) => store.account);
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const onPopupItemClick = (state) => () => {
    setSelectedOrganization(item);
    setModalState(state);
    setIsModalVisible(true);
    setIsComponentVisible(false);
  };

  const onEditClick = (el) => () => {
    navigate(`/organizations/edit?id=${el._id}`);
  };

  const onEditPhotosClick = (el) => () => {
    navigate(`/organizations/edit-photos?id=${el._id}`);
  };

  return (
    <TableRow key={item._id} dashed={!item.is_active}>
      <TableCell width={widths[0]}>{itemsPassed + index + 1}</TableCell>
      <TableCell width={widths[1]}>{item.name}</TableCell>
      <TableCell width={widths[2]}>{item._id}</TableCell>
      <TableCell width={widths[3]}>{item.user}</TableCell>
      <TableCell
        width={widths[4]}
        isIcon
        onClick={() => setIsComponentVisible(true)}
      >
        <img src={DotsIcon} alt="" />
      </TableCell>

      <div ref={ref} className={`table_popup ${isComponentVisible && 'visible'}`}>
        {item.is_active ? (
          <div className="table_popup_item" onClick={onPopupItemClick(ORGANIZATION_MODAL_STATES.block)}>
            <img src={BlockIcon} alt="" />
            <p>Забанить организацию</p>
          </div>
        ) : (
          <div className="table_popup_item" onClick={onPopupItemClick(ORGANIZATION_MODAL_STATES.makeActive)}>
            <img src={RefreshIcon} alt="" />
            <p>Разбанить организацию</p>
          </div>

        )}

        {profile?.can_edit_orgs && (
        <>
          <div className="table_popup_item" onClick={onEditClick(item)}>
            <img src={ChangeOrgIcon} alt="" />
            <p>Изменить данные</p>
          </div>
          <div className="table_popup_item" onClick={onEditPhotosClick(item)}>
            <img src={ChangePhotosIcon} alt="" />
            <p>Изменить фото</p>
          </div>
        </>
        )}

        <div className="table_popup_item" onClick={onPopupItemClick(ORGANIZATION_MODAL_STATES.changeOwner)}>
          <img src={ChangeUserIcon} alt="" />
          <p>Изменить владельца</p>
        </div>
      </div>

    </TableRow>
  );
}

export default OrganizationItem;
