export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAIL = 'GET_USERS_FAIL';
export const getUsersRequest = (data) => ({
  type: GET_USERS_REQUEST,
  payload: data,
});

export const UPDATE_USERS = 'UPDATE_USERS';

export const updateUsers = (data) => ({
  type: UPDATE_USERS,
  payload: data,
});
