import './css/index.scss';
import React, { useState } from 'react';

function TableInput({
  wrapperClassName = '',
  className = '',

  innerRef,
  error,
  withLeftIcon = true,
  ...p
}) {
  return (
    <div className={`table_input_div ${wrapperClassName}`}>

      <input
        ref={innerRef}
        className={`input ${error ? 'error_input' : ''} ${className}`}
        {...p}
      />
    </div>
  );
}

export default TableInput;
