import React from 'react';

import './css/index.scss';

function ReportsFilter({
  filters = [],
  selectedFilter,
  setSelectedFilter,
}) {
  return (
    <div className="reports_filters_block">
      {Boolean(filters?.length) && (
        <div className="table_filters_row">
          {filters.map((filter) => (
            <div
              key={filter.id}
              onClick={() => setSelectedFilter(filter)}
              className={`filter_item ${selectedFilter?.id === filter.id ? 'selected' : ''}`}
            >
              {filter.name}
              <span
                className={`filter_item_count ${selectedFilter?.id === filter.id ? 'selected' : ''}`}
              >
                {filter.count}

              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default ReportsFilter;
