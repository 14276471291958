import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Api from '../../../Api';
import sendRequest from '../../../helpers/sendRequest';

function SelectCity({ setValue, setTempComponentState }) {
  const { countries } = useSelector((store) => store.staticData);

  const [country, setCountry] = useState(null);
  const [thisCities, setThisCities] = useState([]);

  const getThisCities = async (countryId) => {
    const data = await sendRequest({
      request: Api.getCities,
      payload: { country_id: countryId },
      warnErrorText: 'while getting this cities',
    });

    if (data?.status !== 'ok') return;

    setThisCities(data.cities);
  };

  const handleCountryClick = (item) => {
    setCountry(item);
    getThisCities(item._id).then();
  };

  const handleCityClick = (item) => {
    setValue(item);
    setTempComponentState(null);
  };

  return (
    <div className="temp_container">
      <p className="temp_container_title">{!country ? 'Выберите страну' : 'Выберите город'}</p>
      <div className="temp_container_scroll">
        {!country ? countries.map((item) => (
          <div className="temp_container_item" key={item._id} onClick={() => handleCountryClick(item)}>
            <img src={Api.url + item.flag_url} alt="Flag" className="temp_container_item_img" />
            <p className="temp_container_item_text">{item.name}</p>
          </div>
        )) : thisCities.map((item) => (
          <div className="temp_container_item" key={item._id} onClick={() => handleCityClick(item)}>
            <p className="temp_container_item_text">{item.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SelectCity;
