export const GET_ORGANIZATIONS_REQUEST = 'GET_ORGANIZATIONS_REQUEST';
export const GET_ORGANIZATIONS_SUCCESS = 'GET_ORGANIZATIONS_SUCCESS';
export const GET_ORGANIZATIONS_FAIL = 'GET_ORGANIZATIONS_FAIL';
export const getOrganizationsRequest = (data) => ({
  type: GET_ORGANIZATIONS_REQUEST,
  payload: data,
});

export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION';

export const updateOrganization = (data) => ({
  type: UPDATE_ORGANIZATION,
  payload: data,
});
