import React, { useState } from 'react';
import './css/index.scss';
import MyModal from '../../../../components/MyModal';
import MyButton from '../../../../components/MyButton';
import { ARTICLES_MODAL_STATES } from '../../constants';

function ArticlesModal({
  isVisible, setIsVisible, selectedInfo, modalState, onDelete,
}) {
  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    if (loading) {
      return;
    }
    setIsVisible(false);
  };

  const onDeleteClick = async () => {
    setLoading(true);
    await onDelete();
    closeModal();
    setLoading(false);
  };

  return (
    <MyModal isOpen={isVisible} closeFunction={closeModal}>
      {modalState === ARTICLES_MODAL_STATES.delete ? (
        <div className="articles_modal">
          <p className="modal_title">Удалить?</p>

          <p className="modal_info_name">{selectedInfo.name}</p>

          <p className="modal_info">
            Вы уверены что хотите удалить статью? Отменить это действие будет невозможно
          </p>

          <div className="modal_buttons_row">
            <MyButton className="modal_button" text="Отмена" secondary onClick={closeModal} />
            <MyButton
              className="modal_button"
              text="Удалить"
              loading={loading}
              onClick={onDeleteClick}
            />
          </div>
        </div>
      ) : null}

    </MyModal>
  );
}

export default ArticlesModal;
