import React from 'react';
import './css/index.scss';
import CheckboxActiveIcon from '../../assets/formIcons/checkboxActive.svg';
import CheckboxIcon from '../../assets/formIcons/checkbox.svg';

function MyCheckBox({
  wrapperClassName = '', value, setValue, text, imgUrl, disabled, onClick,
}) {
  const handleClick = () => {
    if (disabled) return;
    if (onClick) {
      onClick();
    } else {
      setValue((prev) => !prev);
    }
  };

  return (
    <div className={`checkbox_wrapper ${wrapperClassName} ${disabled && 'disabled'}`} onClick={handleClick}>
      <img src={value ? CheckboxActiveIcon : CheckboxIcon} alt="" />
      {imgUrl && <img src={imgUrl} className="checkbox_img" alt="" />}
      <p className="checkbox_text">{text}</p>
    </div>
  );
}

export default MyCheckBox;
