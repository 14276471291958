import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { SidebarData } from '../../helpers/SidebarData';
import './css/index.scss';
import LocalStorageServices from '../../helpers/LocalStorageServices';
import LogoutIcon from '../../assets/sidebarIcons/logOut.svg';
import { logout, setSystemCountry } from '../../store/actions/account';
import DropIcon from '../../assets/sidebarIcons/drop.svg';
import Api from '../../Api';
import useComponentVisible from '../../helpers/hooks/useComponentVisible';
import IconButton from '../IconButton';

function Sidebar() {
  const dispatch = useDispatch();
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const { profile, allocatedCountries, systemCountry } = useSelector((store) => store.account);
  const { allComplaintsCount } = useSelector((store) => store.reports);
  const location = useLocation();

  const logOut = () => {
    LocalStorageServices.removeToken();
    LocalStorageServices.removeProfile();
    LocalStorageServices.removeSystemCountry();
    dispatch(logout());
  };

  const onDropdownClick = () => {
    if (allocatedCountries.length < 2 || isComponentVisible) return;
    setIsComponentVisible(true);
  };

  const onCountryClick = (country) => {
    LocalStorageServices.setSystemCountry(country);
    setIsComponentVisible(false);
    dispatch(setSystemCountry(country));
  };

  const filteredSidebarData = useMemo(() => {
    if (!profile?._id) return [];

    const list = [];

    if (profile.isAdmin) {
      if (profile.can_edit_countries_and_cities) {
        list.push(SidebarData.cities);
      }
      if (profile.change_info_country_ids?.length) {
        list.push(SidebarData.articles);
        list.push(SidebarData.gallery);
      }
      if (profile.view_complains_country_ids?.length) {
        list.push(SidebarData.reports);
      }
      if (profile.can_modify_users_and_orgs) {
        list.push(SidebarData.users);
      }
      if (profile.can_edit_orgs || profile.can_modify_users_and_orgs) {
        list.push(SidebarData.organizations);
      }
    } else {
      list.push(SidebarData.admins);
    }

    return list;
  }, [profile, SidebarData]);

  return (
    <nav className="navbar">
      <div className="navbar_top">
        <p className="navbar_title">nomad</p>

        {!!allocatedCountries.length && profile.isAdmin && (
        <div
          className={`navbar_country ${(allocatedCountries.length === 1 || isComponentVisible) && 'single'}`}
          onClick={onDropdownClick}
        >
          <img src={Api.url + systemCountry.flag_url} className="navbar_country_flag" alt="" />
          <p className="navbar_country_name">{systemCountry.name}</p>
          <img src={DropIcon} className="navbar_country_drop" alt="" />

          <div ref={ref} className={`table_popup ${isComponentVisible && 'visible'} popup_for_dropdown`}>

            {allocatedCountries.map((item) => (
              <div key={item._id} className="table_popup_item" onClick={() => onCountryClick(item)}>
                <img src={Api.url + item.flag_url} alt="" />
                <p>{item.name}</p>
              </div>
            ))}
          </div>
        </div>
        )}

        <ul className="navbar_list">
          {filteredSidebarData.map((item, index) => {
            const isActive = location.pathname.split('/')[1] === item.path.slice(1);
            return (
              <li key={index} className="navbar_list_item">
                <Link
                  to={item.pathPrefixes?.length ? item.path + item.pathPrefixes[0].path : item.path}
                  replace
                  target={item.target || '_self'}
                  className={`navbar_item ${isActive ? 'selected' : ''}`}
                >
                  <div className="navbar_item_icon">
                    <img
                      src={isActive ? item.selectedIcon : item.icon}
                      alt=""
                      className="navbar_item_icon_img"
                    />
                  </div>
                  <span className="navbar_item_text">{item.title}</span>
                </Link>
                {/* {item.path === SidebarData.reports.path ? <span className="navbar_item_count">{allComplaintsCount}</span> : null} */}
              </li>
            );
          })}
        </ul>
      </div>

      <div className="navbar_logout">
        <p className="navbar_logout_name">
          <span className="navbar_logout_bold">{profile.isAdmin ? profile.display_name : 'Супер админ'}</span>
          <span>
            {profile.isAdmin ? 'Администратор' : 'Root'}
          </span>
        </p>
        <IconButton
          wrapperClassName="navbar_logout_button"
          svgPath={LogoutIcon}
          onClick={logOut}
        />
      </div>
    </nav>
  );
}

export default Sidebar;
