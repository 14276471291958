import checkCoordinates from './checkCoordinates';
import getArrayTrueElementsCount from './getArrayTrueElementsCount';
import getFormData from './getFormData';

function checkAddOrganizationPayload({
  owner_id,
  name,
  category_id,
  sub_category_ids,
  must_set_subcategories,
  country_id,
  city_id,
  specialization,
  address,
  coordinates,
  workTime,
  contacts,
  description,
  languages,
  tags,
  photos,
  withPhotos = true,
}) {
  const errors = [];

  if (owner_id?.length !== 24) {
    errors.push(' * неправильный формат ID пользователя (24 символа)');
  }
  if (name.length < 2) {
    errors.push(' * название не меньше 2 символов');
  } else if (name.length > 100) {
    errors.push(' * название не больше 100 символов');
  }
  if (!category_id) {
    errors.push(' * не выбрана категория');
  }
  if (must_set_subcategories && !sub_category_ids.length) {
    errors.push(' * не выбраны подгатегории');
  }
  if (!city_id) {
    errors.push(' * не выбран город');
  }
  if (specialization.length < 2) {
    errors.push(' * специализация не меньше 2 символов');
  }
  if (address.length < 2) {
    errors.push(' * адрес не меньше 2 символов');
  }
  if (!checkCoordinates(coordinates?.lat, coordinates?.lon)) {
    errors.push(' * не валидные координаты');
  }
  if (getArrayTrueElementsCount(Object.values(workTime)) < 7) {
    errors.push(' * заполните график работы для всех дней');
  }
  if (getArrayTrueElementsCount(Object.values(contacts)) < 1) {
    errors.push(' * укажите хотя бы один из методов контакта с вами');
  }
  if (description.length < 2) {
    errors.push(' * описание не меньше 2 символов');
  }
  if (languages.length < 1) {
    errors.push(' * укажите хотя бы один язык');
  }
  if (tags.length < 1) {
    errors.push(' * укажите хотя бы один тэг');
  }
  if (withPhotos && photos.length < 5) {
    errors.push(' * выберите хотя бы 5 фото');
  } else if (withPhotos && photos.length > 15) {
    errors.push(' * выберите не больше 15 фото');
  }

  if (errors.length) {
    return { errors };
  }

  const payload = {
    owner_id,
    name,
    category_id,
    sub_category_ids,
    country_id,
    city_id,
    specialization,
    address,
    lat: coordinates.lat.toString(),
    lon: coordinates.lon.toString(),
    is_special_point_on_map: Boolean(coordinates.is_special_point_on_map),
    work_time: workTime,
    contacts,
    description,
    languages_ids: languages.map((item) => item._id),
    tags,
  };

  if (withPhotos) {
    const formData = getFormData(payload, photos);
    return { formData };
  }
  return { payload };
}

export default checkAddOrganizationPayload;
