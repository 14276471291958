import React, { useEffect, useState } from 'react';
import './css/index.scss';
import { useDispatch, useSelector } from 'react-redux';
import PageTitle from '../../components/PageTitle';
import ReportsFilter from './components/ReportsFilter';
import { REPORT_FILTERS } from './constants';
import ReportComments from './components/ReportComments';
import ReportPhotos from './components/ReportPhotos';
import ReportInfos from './components/ReportInfos';
import ReportOrganizations from './components/ReportOrganizations';
import { getComplaintsRequest, updateComplaintCount, updateComplaints } from '../../store/actions/reports';

function Reports() {
  const dispatch = useDispatch();
  const { systemCountry } = useSelector((store) => store.account);
  const { complaints, complaintCounts, complaintsLoading } = useSelector((store) => store.reports);
  const [selectedFilter, setSelectedFilter] = useState(REPORT_FILTERS[0]);

  useEffect(() => {
    if (systemCountry?._id && selectedFilter) {
      dispatch(getComplaintsRequest({ systemCountry, selectedFilter }));
    }
  }, [systemCountry, selectedFilter, dispatch]);

  const handleUpdateComplaints = (complaintId, answer, complaintType) => {
    if (['photo', 'review'].includes(complaintType) && answer === 'approve') {
      dispatch(getComplaintsRequest({ systemCountry, selectedFilter }));
    } else {
      dispatch(updateComplaints(complaintId, answer, selectedFilter));
      dispatch(updateComplaintCount(selectedFilter.id));
    }
  };

  const componentMap = {
    review: ReportComments,
    photo: ReportPhotos,
    organization: ReportOrganizations,
    info: ReportInfos,
  };

  const RenderedComponent = selectedFilter ? componentMap[selectedFilter.id] : null;

  return (
    <div className="reports">
      <PageTitle text="Репорты" />
      <ReportsFilter
        filters={REPORT_FILTERS.map((filter) => ({
          ...filter,
          count: complaintCounts[filter.id],
        }))}
        selectedFilter={selectedFilter}
        setSelectedFilter={setSelectedFilter}
      />
      {RenderedComponent && (
        complaintsLoading ? null : (
          <RenderedComponent
            complaints={complaints}
            updateComplaints={handleUpdateComplaints}
          />
        )
      )}
    </div>
  );
}

export default Reports;
