import React from 'react';
import './css/index.scss';

function TableCell({
  children, width, isIcon, onClick,
}) {
  return (
    <div
      className={`table_cell_item ${isIcon && 'is_icon'}`}
      style={{ width: `${width}%` }}
      onClick={onClick}
    >
      {onClick ? (
        <div className="clickable">
          {children}
        </div>
      ) : children}
    </div>
  );
}

export default TableCell;
