import AirplaneIcon from '../assets/infoTableIcons/airplane.svg';
import VisaIcon from '../assets/infoTableIcons/visa.svg';
import CityIcon from '../assets/infoTableIcons/city.svg';
import BoravakIcon from '../assets/infoTableIcons/borayak.svg';
import ImmVisaIcon from '../assets/infoTableIcons/immVisa.svg';
import BankIcon from '../assets/infoTableIcons/bank.svg';
import ExchangeIcon from '../assets/infoTableIcons/exchange.svg';
import TransferIcon from '../assets/infoTableIcons/transfer.svg';
import HomeIcon from '../assets/infoTableIcons/home.svg';
import InsuranceIcon from '../assets/infoTableIcons/insurance.svg';
import MedicalCrossIcon from '../assets/infoTableIcons/medicalCross.svg';
import SchoolIcon from '../assets/infoTableIcons/school.svg';
import KindersIcon from '../assets/infoTableIcons/kinder.svg';
import UniversityIcon from '../assets/infoTableIcons/university.svg';
import BallIcon from '../assets/infoTableIcons/ball.svg';
import AntennaIcon from '../assets/infoTableIcons/antenna.svg';
import MenuIcon from '../assets/infoTableIcons/menu.svg';
import BusIcon from '../assets/infoTableIcons/bus.svg';
import CarIcon from '../assets/infoTableIcons/car.svg';
import DogIcon from '../assets/infoTableIcons/dog.svg';
import DogDocsIcon from '../assets/infoTableIcons/dogdocs.svg';

export const infoTableIcons = {
  Туризм: {
    'Как добраться': AirplaneIcon,
    'Визовый режим': VisaIcon,
    Города: CityIcon,
  },
  Легализация: {
    'Иммиграционные визы': ImmVisaIcon,
    ВНЖ: BoravakIcon,
  },
  Финансы: {
    'Открытие счета': BankIcon,
    'Обмен валюты': ExchangeIcon,
    Трансферы: TransferIcon,
  },
  Недвижимость: {
    'Общая информация': HomeIcon,
  },
  Медицина: {
    'Общая информация': InsuranceIcon,
    'Медицинская страховка': MedicalCrossIcon,
  },
  Образование: {
    Школьное: SchoolIcon,
    Дошкольное: KindersIcon,
    'Колледжи / Университеты': UniversityIcon,
    'Кружки / Секции': BallIcon,
  },

  'Связь и интернет': {
    Операторы: AntennaIcon,
    Пакеты: MenuIcon,
    Интернет: HomeIcon,
  },

  Транспорт: {
    'Общественный транспорт': BusIcon,
    'Личный автомобиль': CarIcon,
  },

  Животные: {
    'Правила перевозки': DogIcon,
    Регистрация: DogDocsIcon,
  },
};
