import { Outlet, Navigate } from 'react-router';
import Wrapper from '../components/Wrapper';

export default function ({ hasAccess }) {
  return hasAccess ? (
    <Wrapper>
      <Outlet />
    </Wrapper>
  ) : <Navigate to="/login" replace />;
}
