import React from 'react';
import './css/index.scss';

function TableRow({ children, dashed }) {
  return (
    <div className={`table_row ${dashed ? 'dashed' : ''}`}>
      {children}
    </div>
  );
}

export default TableRow;
