export const GET_COUNTRIES_REQUEST = 'GET_COUNTRIES_REQUEST';
export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS';
export const GET_COUNTRIES_FAIL = 'GET_COUNTRIES_FAIL';

export const getCountriesRequest = (data) => ({
  type: GET_COUNTRIES_REQUEST,
  payload: data,
});

export const GET_INFO_TABLE_REQUEST = 'GET_INFO_TABLE_REQUEST';
export const GET_INFO_TABLE_SUCCESS = 'GET_INFO_TABLE_SUCCESS';
export const GET_INFO_TABLE_FAIL = 'GET_INFO_TABLE_FAIL';

export const getInfoTableRequest = (data) => ({
  type: GET_INFO_TABLE_REQUEST,
  payload: data,
});

export const GET_CATEGORIES_REQUEST = 'GET_CATEGORIES_REQUEST';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export function getCategoriesRequest(payload = {}) {
  return {
    type: GET_CATEGORIES_REQUEST,
    payload,
  };
}

export const GET_LANGUAGES_REQUEST = 'GET_LANGUAGES_REQUEST';
export const GET_LANGUAGES_SUCCESS = 'GET_LANGUAGES_SUCCESS';
export function getLanguagesRequest(payload) {
  return {
    type: GET_LANGUAGES_REQUEST,
    payload,
  };
}

export const SET_INFO_TABLE = 'SET_INFO_TABLE';
export const setInfoTable = (data) => ({
  type: SET_INFO_TABLE,
  payload: data,
});

export const UPDATE_INFO_TABLE_BLOCK = 'UPDATE_INFO_TABLE_BLOCK';
export const updateInfoTableBlock = (data) => ({
  type: UPDATE_INFO_TABLE_BLOCK,
  payload: data,
});

export const ADD_COUNTRY = 'ADD_COUNTRY';
export const addCountry = (data) => ({
  type: ADD_COUNTRY,
  payload: data,
});

export const UPDATE_COUNTRY = 'UPDATE_COUNTRY';
export const updateCountry = (data) => ({
  type: UPDATE_COUNTRY,
  payload: data,
});

export const DELETE_COUNTRY = 'DELETE_COUNTRY';
export const deleteCountryFromStorage = (data) => ({
  type: DELETE_COUNTRY,
  payload: data,
});
