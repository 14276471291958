class LocalStorageServices {
  static getToken = () => localStorage.getItem('token') || '';

  static setToken = (token) => {
    localStorage.setItem('token', token);
  };

  static removeToken = () => {
    localStorage.removeItem('token');
  };

  static getProfile = () => {
    const profile = localStorage.getItem('profile');
    if (typeof profile !== 'string') {
      return {};
    }
    return JSON.parse(profile);
  };

  static setProfile = (data) => {
    localStorage.setItem('profile', JSON.stringify(data));
  };

  static removeProfile = () => {
    localStorage.removeItem('profile');
  };

  static getSystemCountry = () => {
    const country = localStorage.getItem('systemCountry');
    if (typeof country !== 'string') {
      return {};
    }
    return JSON.parse(country);
  };

  static setSystemCountry = (data) => {
    localStorage.setItem('systemCountry', JSON.stringify(data));
  };

  static removeSystemCountry = () => {
    localStorage.removeItem('systemCountry');
  };
}

export default LocalStorageServices;
