import {
  GET_USERS_FAIL, GET_USERS_REQUEST, GET_USERS_SUCCESS, UPDATE_USERS,
} from '../actions/users';

const initialState = {
  users: [],
  usersTotal: 0,
  usersLoading: false,
  usersError: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_USERS_REQUEST: {
      return {
        ...state,
        usersLoading: true,
        usersError: false,
      };
    }
    case GET_USERS_SUCCESS: {
      return {
        ...state,
        users: action.payload.users,
        usersTotal: action.payload.total,
        usersLoading: false,
        usersError: false,
      };
    }

    case GET_USERS_FAIL: {
      return {
        ...state,
        usersLoading: false,
        usersError: true,
      };
    }
    case UPDATE_USERS: {
      const newUsers = state.users.map(
        (item) => (item._id === action.payload._id ? Object.assign(item, action.payload) : item),
      );
      return {
        ...state,
        usersLoading: false,
        usersError: true,
        users: newUsers,
      };
    }

    default: {
      return state;
    }
  }
}
