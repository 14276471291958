function includesObject(arr, object, field = '_id') {
  for (const arrElement of arr) {
    if (arrElement[field] === object[field]) {
      return true;
    }
  }

  return false;
}

export default includesObject;
