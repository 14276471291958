import React, { useEffect, useRef, useState } from 'react';
import './css/index.scss';
import { useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import PageTitle from '../../components/PageTitle';
import MyButton from '../../components/MyButton';
import AddPhotosIcon from '../../assets/formIcons/addPhotos.svg';
import GalleryInfoModal from './components/GalleryInfoModal';
import { GALLERY_MODAL_STATES } from './constants';
import InfoTable from '../../components/InfoTable';
import getFormData from '../../helpers/getFormData';
import sendRequest from '../../helpers/sendRequest';
import DeletePhotoIcon from '../../assets/formIcons/deletePhoto.svg';
import CopyPhotoIcon from '../../assets/formIcons/copyPhoto.svg';
import Api from '../../Api';

import 'react-toastify/dist/ReactToastify.min.css';

function Gallery(props) {
  const selectPhotosInput = useRef();
  const { systemCountry } = useSelector((store) => store.account);
  const { infoTable } = useSelector((store) => store.staticData);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalState, setModalState] = useState('');
  const [selectedPhoto, setSelectedPhoto] = useState({});
  const [selectedInfo, setSelectedInfo] = useState({});
  const [addingLoading, setAddingLoading] = useState(false);
  const [photos, setPhotos] = useState([]);

  const onAddClick = () => {
    if (typeof selectPhotosInput.current?.click === 'function') {
      selectPhotosInput.current.click();
    }
  };

  const onPhotosSelect = async (ev) => {
    const { files: filesList } = ev.target;
    const files = Array.from(filesList);
    if (!filesList.length) return;

    if (files.length > 15) {
      setModalState(GALLERY_MODAL_STATES.maxCountError);
      setIsModalVisible(true);
    }

    const payload = {
      country_id: systemCountry._id,
      information_block_id: selectedInfo.information_block_id,
      information_item_id: selectedInfo._id,
    };

    const formData = getFormData(payload, files.slice(0, 15));

    const data = await sendRequest({
      request: Api.addPhotosToGallery,
      payload: formData,
      warnErrorText: 'while adding photos to gallery',
      setLoading: setAddingLoading,
    });

    if (data?.status !== 'ok') return;

    setPhotos((prev) => [...data.gallery, ...prev]);
  };

  const onInfoClick = async (val) => {
    setSelectedInfo(val);

    const payload = {
      information_item_id: val._id,
      country_id: systemCountry._id,
    };

    const data = await sendRequest({
      request: Api.getGalleryPhotos,
      payload,
      warnErrorText: 'while getting gallery photos',
    });

    if (data?.status !== 'ok') return;

    setPhotos(data.gallery);
  };

  const onPhotoItemClick = (photo) => () => {
    setSelectedPhoto(photo);
    setModalState(GALLERY_MODAL_STATES.viewPhoto);
    setIsModalVisible(true);
  };

  const onDeletePhotoClick = (photo) => (ev) => {
    ev.stopPropagation();
    setSelectedPhoto(photo);
    setModalState(GALLERY_MODAL_STATES.delete);
    setIsModalVisible(true);
  };

  const onCopyPhotoClick = (photo) => (ev) => {
    ev.stopPropagation();
    toast.success('Скопировано в буфер обмена');
    const { width, height, url } = photo.photo;
    const newWidth = 326;
    const ratio = width / 326;
    const newHeight = height / ratio;
    navigator.clipboard.writeText(
      `<img originalWidth="${width}" originalHeight="${height}" width="${newWidth}" height="${newHeight}" src="${url}" />\n`,
    );
  };

  useEffect(() => {
    if (infoTable?.length) {
      onInfoClick(infoTable[0].items[0]).then();
    }
  }, [infoTable]);

  return (
    <div className="gallery_wrapper">
      <PageTitle
        text="Галерея"
        RightComponent={(
          <>
            <MyButton
              text="Добавить"
              svgPath={AddPhotosIcon}
              onClick={onAddClick}
              className="gallery_add_btn"
              loading={addingLoading}
            />
            <input
              ref={selectPhotosInput}
              type="file"
              multiple
              accept="image/png, image/jpeg"
              className="gallery_select_photos"
              onChange={onPhotosSelect}
            />
          </>
                )}
      />

      <div className="gallery_container">
        <div className="gallery_left">
          <InfoTable
            selectedInfo={selectedInfo}
            setSelectedInfo={setSelectedInfo}
            onClick={onInfoClick}
          />
        </div>
        <div className="gallery_table">
          {photos.length ? (
            photos.map((photo) => (
              <div
                key={photo._id}
                className="gallery_table_item"
                onClick={onPhotoItemClick(photo)}
              >

                <img
                  src={Api.url + photo.photo?.url}
                  alt=""
                  className="gallery_table_item_photo"
                />

                <span className="gallery_table_item_title">{photo.photo?.title || 'No title'}</span>

                <div className="gallery_item_overlay">
                  <div
                    className="gallery_item_overlay_icon"
                    onClick={onDeletePhotoClick(photo)}
                  >
                    <img src={DeletePhotoIcon} alt="" />
                  </div>
                  <div className="gallery_item_overlay_icon" onClick={onCopyPhotoClick(photo)}>
                    <img src={CopyPhotoIcon} alt="" />
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p className="gallery_no_photo">Нет фото в этой папке</p>
          )}
        </div>
      </div>

      <GalleryInfoModal
        isVisible={isModalVisible}
        setIsVisible={setIsModalVisible}
        modalState={modalState}
        setPhotos={setPhotos}
        selectedPhoto={selectedPhoto}
        setSelectedPhoto={setSelectedPhoto}
        systemCountry={systemCountry}
      />

      <ToastContainer pauseOnHover={false} autoClose={2000} />
    </div>
  );
}

export default Gallery;
