import React from 'react';
import './css/index.scss';
import TableCell from '../../../../components/TableCell';
import CloseIcon from '../../../../assets/formIcons/close.svg';
import RefreshIcon from '../../../../assets/formIcons/refresh.svg';
import TableRow from '../../../../components/TableRow';
import { USER_MODAL_STATES } from '../../constants';
import avatarIcon from '../../../../assets/formIcons/avatar.svg';

function UserItem({
  item, itemsPassed, widths, index, setSelectedUser, setModalState, setIsModalVisible,
}) {
  const onPopupItemClick = (state) => () => {
    setSelectedUser(item);
    setModalState(state);
    setIsModalVisible(true);
  };

  return (
    <TableRow key={item._id} dashed={!item.is_active}>
      <TableCell width={widths[0]}>
        <div className="user_item_index">
          {itemsPassed + index + 1}
          <img src={avatarIcon} alt="Avatar" />
        </div>
      </TableCell>
      <TableCell width={widths[1]}>{item.display_name}</TableCell>
      <TableCell width={widths[2]}>{item._id}</TableCell>
      <TableCell width={widths[3]}>{item.email}</TableCell>
      <TableCell
        width={widths[4]}
        isIcon
        onClick={onPopupItemClick(item.is_active ? USER_MODAL_STATES.block : USER_MODAL_STATES.makeActive)}
      >
        {item.is_active ? (
          <img src={CloseIcon} alt="" />
        ) : (
          <img src={RefreshIcon} alt="" />
        )}
      </TableCell>

    </TableRow>
  );
}

export default UserItem;
