import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import MyButton from '../../../components/MyButton';
import CloseSmallIcon from '../../../assets/formIcons/closeSmall.svg';
import SearchInput from '../../../components/SearchInput';
import useInputState from '../../../helpers/hooks/useInputState';

function SelectTags({
  setValue, setTempComponentState, initValue = [],
}) {
  const [selected, setSelected] = useState(initValue);
  const [inputVal, setInputVal] = useInputState();

  const handleInputKeyDown = (ev) => {
    if (['Tab', 'Enter', 'Space', 'Comma'].includes(ev.code)) {
      addNewTag(inputVal);
      setInputVal('', true);
    }
  };

  const addNewTag = (tag) => {
    if (!tag || !tag.trim().length || selected.includes(tag)) return;
    setSelected((prev) => [tag.trim(), ...prev]);
  };

  const onTagClick = (tag) => () => {
    setSelected((prev) => prev.filter((el) => el !== tag));
  };

  const handleSubmit = () => {
    setValue(selected);
    setTempComponentState(null);
  };

  return (
    <div className="temp_container">
      <p className="temp_container_title">Укажите тэги</p>
      <div className="temp_container_scroll with_button">
        <SearchInput
          withLeftIcon={false}
          value={inputVal}
          onChange={setInputVal}
          onKeyDown={handleInputKeyDown}
          labelText="Enter, Tab, Space"
        />
        <div className="temp_container_tags_wrapper">
          {selected.map((item) => (
            <div
              key={item}
              className="tag_wrapper"
              onClick={onTagClick(item)}
            >
              <p className="tag_wrapper_text">{item}</p>
              <img src={CloseSmallIcon} alt="X" className="tag_wrapper_img" />
            </div>
          ))}
        </div>
      </div>

      <MyButton onClick={handleSubmit} text="Ок" className="temp_container_button" />
    </div>
  );
}

export default SelectTags;
