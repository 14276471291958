import React, { useState } from 'react';
import './css/index.scss';
import { useDispatch } from 'react-redux';
import MyModal from '../../../../components/MyModal';
import { ORGANIZATION_MODAL_STATES } from '../../constants';
import MyButton from '../../../../components/MyButton';
import Api from '../../../../Api';
import sendRequest from '../../../../helpers/sendRequest';
import { updateOrganization } from '../../../../store/actions/organizations';
import SearchInput from '../../../../components/SearchInput';
import useInputState from '../../../../helpers/hooks/useInputState';

function OrganizationModal({
  isVisible, setIsVisible, modalState, selectedOrganization = {}, setSelectedOrganization,
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [newUserId, setNewUserId, newUserIdError, setNewUserIdError] = useInputState('');

  const closeModal = () => {
    if (loading) {
      return;
    }
    setIsVisible(false);
    setTimeout(() => {
      setSelectedOrganization({});
      setNewUserId('', true);
    }, 400);
  };

  const onActiveChange = (newActiveVal) => async () => {
    const payload = {
      organization_id: selectedOrganization._id,
      is_active: newActiveVal,
    };

    const data = await sendRequest({
      request: Api.blockOrganization,
      payload,
      warnErrorText: 'while blocking organization',
      setLoading,
    });

    if (data?.status === 'ok') {
      dispatch(updateOrganization({ ...selectedOrganization, is_active: newActiveVal }));
      closeModal();
    }
  };

  const onChangeClick = async () => {
    const payload = {
      organization_id: selectedOrganization._id,
      new_user_id: newUserId,
    };

    const data = await sendRequest({
      request: Api.changeOrganizationOwner,
      payload,
      warnErrorText: 'while changing organization owner',
      setLoading,
    });

    if (data?.status === 'ok') {
      dispatch(updateOrganization({ ...selectedOrganization, user: newUserId }));
      closeModal();
    } else if (data?.message === 'This user is banned') {
      setNewUserIdError('Этот пользователь забанен');
    } else if (data?.message === 'This user is not confirmed') {
      setNewUserIdError('Этот пользователь не подтверждён');
    } else {
      setNewUserIdError('Такого пользователя не существует');
    }
  };

  return (
    <MyModal isOpen={isVisible} closeFunction={closeModal}>
      <div className="organization_modal">
        {modalState === ORGANIZATION_MODAL_STATES.block ? (
          <>
            <p className="modal_title">Забанить?</p>
            <p className="modal_info_name">{selectedOrganization.name}</p>
            <p className="modal_info">Вы уверены что хотите отправить эту организацию в бан-лист?</p>

            <div className="modal_buttons_row">
              <MyButton className="modal_button" text="Отмена" secondary onClick={closeModal} />
              <MyButton
                className="modal_button"
                text="Забанить"
                loading={loading}
                onClick={onActiveChange(false)}
              />
            </div>
          </>
        ) : modalState === ORGANIZATION_MODAL_STATES.makeActive ? (
          <>
            <p className="modal_title">Разбанить?</p>
            <p className="modal_info_name">{selectedOrganization.name}</p>
            <p className="modal_info">Вы уверены что хотите снять эту организацию из бан-листа?</p>

            <div className="modal_buttons_row">
              <MyButton className="modal_button" text="Отмена" secondary onClick={closeModal} />
              <MyButton
                className="modal_button"
                text="Разбанить"
                loading={loading}
                onClick={onActiveChange(true)}
              />
            </div>
          </>
        ) : modalState === ORGANIZATION_MODAL_STATES.changeOwner ? (
          <>
            <p className="modal_title">Изменить владельца?</p>

            <div className="modal_simple_block">
              <p className="modal_simple_block_title">{selectedOrganization.name}</p>
              <p className="modal_simple_block_descr">Название организации</p>
            </div>
            <div className="modal_simple_block">
              <p className="modal_simple_block_title">{selectedOrganization.user}</p>
              <p className="modal_simple_block_descr">ID владельца</p>
            </div>

            <SearchInput
              wrapperClassName="modal_input"
              value={newUserId}
              onChange={setNewUserId}
              error={newUserIdError}
              placeholder="Введите ID нового владельца"
              withLeftIcon={false}
            />

            <div className="modal_buttons_row">
              <MyButton className="modal_button" text="Отмена" secondary onClick={closeModal} />
              <MyButton
                className="modal_button"
                text="Изменить"
                loading={loading}
                onClick={onChangeClick}
              />
            </div>
          </>
        ) : modalState === ORGANIZATION_MODAL_STATES.changeData ? (
          <>myau</>
        )
          : null}

      </div>
    </MyModal>
  );
}

export default OrganizationModal;
