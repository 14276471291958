import React from 'react';
import './css/index.scss';
import Sidebar from '../SideBar';

function Wrapper({ children }) {
  return (
    <div className="wrapper">
      <Sidebar />
      <div className="container">
        <div className="container_inner">
          {children}
        </div>
      </div>
    </div>
  );
}

export default Wrapper;
