import React, { useMemo } from 'react';
import './css/index.scss';
import NoInfo from '../../../../assets/images/noInfo.png';
import { REGEXP } from '../../../../helpers/constants';
import Api from '../../../../Api';
import checkIfHtmlTextHasJs from '../../../../helpers/checkIfHtmlTextHasJs';

function ArticlesViewBlock({ innerHtml }) {
  const changedHtml = useMemo(() => {
    if (checkIfHtmlTextHasJs(innerHtml)) {
      return 'Есть недопустимые символы';
    }
    return innerHtml
      .replace(REGEXP.margin, ' style="margin-bottom: $1px" />')
      .replace(REGEXP.imgSrc, ` src="${Api.url}$1" />`);
  }, [innerHtml]);

  return (
    <div className="articles_view_block_wrapper">
      {!changedHtml ? (
        <div className="articles_view_block_no_info">
          <p className="articles_view_block_no_info_text">Информация отсутствует</p>
          <img className="articles_view_block_no_info_img" src={NoInfo} alt="" />
        </div>
      ) : (
        <div
          className="articles_view_block_main"
          dangerouslySetInnerHTML={{ __html: changedHtml }}
        />
      )}

    </div>
  );
}

export default ArticlesViewBlock;
