import React, { useState } from 'react';
import './css/index.scss';
import MyModal from '../../../../components/MyModal';
import MyButton from '../../../../components/MyButton';
import { GALLERY_MODAL_STATES } from '../../constants';
import sendRequest from '../../../../helpers/sendRequest';
import Api from '../../../../Api';

function GalleryInfoModal({
  isVisible,
  setIsVisible,
  selectedPhoto,
  setSelectedPhoto,
  modalState,
  setPhotos,
  systemCountry,
}) {
  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    if (loading) {
      return;
    }
    setIsVisible(false);
    setTimeout(() => {
      setSelectedPhoto({});
    }, 400);
  };

  const onDeleteClick = async () => {
    const data = await sendRequest({
      request: Api.deletePhotosToGallery,
      payload: { id: selectedPhoto._id, params: { country_id: systemCountry._id } },
      warnErrorText: 'while deleting photo',
      setLoading,
    });

    if (data?.status === 'ok') {
      setPhotos((prev) => prev.filter((item) => item._id !== selectedPhoto._id));
      closeModal();
    }
  };

  return (
    <MyModal
      isOpen={isVisible}
      closeFunction={closeModal}
      style={modalState === GALLERY_MODAL_STATES.viewPhoto ? { padding: 0, borderRadius: 0, borderWidth: 0 } : {}}
    >
      {modalState === GALLERY_MODAL_STATES.maxCountError ? (
        <div className="gallery_modal">
          <p className="modal_title">Внимание</p>

          <p className="modal_info">
            Максимальное количество фото для загрузки: 15. Вы выбрали больше - сохранятся только первые 15
            фото
          </p>

          <div className="modal_buttons_row">
            <MyButton className="modal_button single" text="ОК" onClick={closeModal} />
          </div>
        </div>
      ) : modalState === GALLERY_MODAL_STATES.delete ? (
        <div className="gallery_modal">
          <p className="modal_title">Удалить?</p>

          <img className="modal_photo" src={Api.url + (selectedPhoto.photo?.url || '')} alt="" />

          <p className="modal_info">
            Вы уверены что хотите удалить эту фотографию? Отменить данное
            действие будет невозможно.
          </p>

          <div className="modal_buttons_row">
            <MyButton className="modal_button" text="Отмена" secondary onClick={closeModal} />
            <MyButton
              className="modal_button"
              text="Удалить"
              loading={loading}
              onClick={onDeleteClick}
            />
          </div>
        </div>
      ) : modalState === GALLERY_MODAL_STATES.viewPhoto ? (
        <div className="gallery_modal_big">
          <img className="modal_photo_big" src={Api.url + (selectedPhoto.photo?.url || '')} alt="" />
        </div>
      ) : null}

    </MyModal>
  );
}

export default GalleryInfoModal;
