const SPLIT_WORD = 'jkdi76&^&STAGDUT*#';

const regexpAndColorList = [
  {
    name: 'ul',
    color: '#C100B9',
    regexp: /(<ul>)((.|\n)*?)(<\/ul>)/gm,
    convertTo() {
      return `${SPLIT_WORD}<span style="color: ${this.color}">$1</span>${SPLIT_WORD}$2${SPLIT_WORD}<span style="color: ${this.color}">$4</span>${SPLIT_WORD}`;
    },
  },
  {
    name: 'li',
    color: '#dc1ed4',
    regexp: /(<li>)((.|\n)*?)(<\/li>)/gm,
    convertTo() {
      return `${SPLIT_WORD}<span style="color: ${this.color}">$1</span>${SPLIT_WORD}$2${SPLIT_WORD}<span style="color: ${this.color}">$4</span>${SPLIT_WORD}`;
    },
  },
  {
    name: 'pre',
    color: '#433FF9',
    regexp: /(<pre>)((.|\n)*?)(<\/pre>)/gm,
    convertTo() {
      return `${SPLIT_WORD}<span style="color: ${this.color}">$1</span>${SPLIT_WORD}$2${SPLIT_WORD}<span style="color: ${this.color}">$4</span>${SPLIT_WORD}`;
    },
  },
  {
    name: 'b',
    color: '#002C9D',
    regexp: /(<b>)((.|\n)*?)(<\/b>)/gm,
    convertTo(i) {
      return `${SPLIT_WORD}<span style="color: ${this.color}">$1</span>${SPLIT_WORD}$2${SPLIT_WORD}<span style="color: ${this.color}">$4</span>${SPLIT_WORD}`;
    },
  },
  {
    name: 'h1',
    color: '#C80000',
    regexp: /(<h1>)((.|\n)*?)(<\/h1>)/gm,
    convertTo() {
      return `${SPLIT_WORD}<span style="color: ${this.color}">$1</span>${SPLIT_WORD}$2${SPLIT_WORD}<span style="color: ${this.color}">$4</span>${SPLIT_WORD}`;
    },
  },
  {
    name: 'h2',
    color: '#C17400',
    regexp: /(<h2>)((.|\n)*?)(<\/h2>)/gm,
    convertTo() {
      return `${SPLIT_WORD}<span style="color: ${this.color}">$1</span>${SPLIT_WORD}$2${SPLIT_WORD}<span style="color: ${this.color}">$4</span>${SPLIT_WORD}`;
    },
  },
  {
    name: 'h3',
    color: '#BDC100',
    regexp: /(<h3>)((.|\n)*?)(<\/h3>)/gm,
    convertTo() {
      return `${SPLIT_WORD}<span style="color: ${this.color}">$1</span>${SPLIT_WORD}$2${SPLIT_WORD}<span style="color: ${this.color}">$4</span>${SPLIT_WORD}`;
    },
  },
  {
    name: 'h4',
    color: '#64c100',
    regexp: /(<h4>)((.|\n)*?)(<\/h4>)/gm,
    convertTo() {
      return `${SPLIT_WORD}<span style="color: ${this.color}">$1</span>${SPLIT_WORD}$2${SPLIT_WORD}<span style="color: ${this.color}">$4</span>${SPLIT_WORD}`;
    },
  },
  {
    name: 'a',
    color: '#009EC1',
    regexp: /(<a (.|\n)*?>)((.|\n)*?)(<\/a>)/gm,
    convertTo() {
      return `${SPLIT_WORD}<span style="color: ${this.color}">$1</span>${SPLIT_WORD}$3${SPLIT_WORD}<span style="color: ${this.color}">$5</span>${SPLIT_WORD}`;
    },
  },
  {
    name: 'img',
    color: '#00C808',
    regexp: /(<img ((.|\n)*?) \/>)/gm,
    convertTo() {
      return `${SPLIT_WORD}<span style="color: ${this.color}">$1</span>${SPLIT_WORD}`;
    },
  },
  {
    name: 'sub',
    color: '#009065',
    regexp: /(<sub (.|\n)*?>)((.|\n)*?)(<\/sub>)/gm,
    convertTo() {
      return `${SPLIT_WORD}<span style="color: ${this.color}">$1</span>${SPLIT_WORD}$3${SPLIT_WORD}<span style="color: ${this.color}">$5</span>${SPLIT_WORD}`;
    },
  },
];

const wrapTextIntoSpan = (initText, color, visibleRef) => {
  const text = document.createTextNode(initText);
  const span = document.createElement('span');
  // span.style.backgroundColor = `${color}10`;
  span.style.color = color;
  span.appendChild(text);

  visibleRef.current.appendChild(span);
};

const getModifiedHtml = (html, visibleRef) => {
  let newHtml = html;
  for (let i = 0; i < regexpAndColorList.length; i++) {
    newHtml = newHtml.replaceAll(regexpAndColorList[i].regexp, regexpAndColorList[i].convertTo(i));
  }

  const reg = /(<span style="color: ((.|\n)*?)">)((.|\n)*?)(<\/span>)/gm;
  visibleRef.current.innerHTML = '';

  newHtml.split(SPLIT_WORD).forEach((htmlPart) => {
    if (!htmlPart?.length) return;

    let result;
    let wasInRegexp = false;

    // eslint-disable-next-line no-cond-assign
    while (result = reg.exec(htmlPart)) {
      wasInRegexp = true;
      wrapTextIntoSpan(result[4], result[2], visibleRef);
    }

    if (!wasInRegexp) {
      const text = document.createTextNode(htmlPart);
      visibleRef.current.appendChild(text);
    }
  });
};

export default getModifiedHtml;
