export const USER_FILTERS = [
  { id: 'all', name: 'Все' },
  { id: 'true', name: 'Активные' },
  { id: 'false', name: 'Забаненные' },
];

export const USER_WIDTHS = [8, 38, 23, 23, 3]; // margin right

export const USER_HEADER_COLUMNS = [
  { text: '№', width: USER_WIDTHS[0] },
  { text: 'Имя пользователя', width: USER_WIDTHS[1] },
  { text: 'ID', width: USER_WIDTHS[2] },
  { text: 'Email', width: USER_WIDTHS[3] },
  { text: '', width: USER_WIDTHS[4] }];

export const USER_MODAL_STATES = {
  block: 'block',
  makeActive: 'makeActive',
};
