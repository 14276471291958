import { call, put, takeLatest } from 'redux-saga/effects';
import Api from '../../Api';
import { GET_USERS_FAIL, GET_USERS_REQUEST, GET_USERS_SUCCESS } from '../actions/users';

export default function* watcher() {
  yield takeLatest(GET_USERS_REQUEST, getUsers);
}

function* getUsers(action) {
  try {
    const { data } = yield call(Api.getUsers, action.payload);

    yield put({
      type: GET_USERS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.warn(error, 'while getting users');

    let errorMessage = 'Unknown error';

    if (error.response) {
      errorMessage = error.response.data.message || 'Unknown server error';
    } else if (error.request) {
      errorMessage = 'No response from the server';
    } else {
      errorMessage = error.message || 'Unknown error';
    }

    yield put({
      type: GET_USERS_FAIL,
      payload: errorMessage,
    });
  }
}
