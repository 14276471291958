import React, { useState } from 'react';
import { useSelector } from 'react-redux';

function SelectCategory({ setValue, setTempComponentState }) {
  const { categories } = useSelector((store) => store.staticData);

  const handleClick = (item) => {
    setValue(item);
    setTempComponentState(null);
  };

  return (
    <div className="temp_container">
      <p className="temp_container_title">Выберите категорию</p>
      <div className="temp_container_scroll">
        {categories.map((item) => (
          <div className="temp_container_item" key={item._id} onClick={() => handleClick(item)}>
            <p className="temp_container_item_text">{item.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SelectCategory;
