import React, { useState } from 'react';
import './css/index.scss';
import moment from 'moment';
import MyButton from '../../../../components/MyButton';
import StartIcon from '../../../../assets/formIcons/star.svg';
import EmptyStarIcon from '../../../../assets/formIcons/emptyStar.svg';
import Api from '../../../../Api';
import { REPORT_MODAL_STATES } from '../../constants';
import ReportsModal from '../ReportsModal';

const generateStars = (rating) => {
  const maxRating = 5;
  const stars = [];

  for (let i = 1; i <= maxRating; i++) {
    stars.push(
      <img
        key={i}
        src={i <= rating ? StartIcon : EmptyStarIcon}
        alt="Star"
        className="report_item_review_rating_avatar"
      />,
    );
  }

  return stars;
};

function ReportComments({ complaints, updateComplaints }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalState, setModalState] = useState('');
  const [selectedPhoto, setSelectedPhoto] = useState({});
  const [complaintId, setComplaintId] = useState(null);

  const onDeleteClick = (complaint) => {
    setModalState(REPORT_MODAL_STATES.approve);
    setIsModalVisible(true);
    setComplaintId(complaint);
  };

  const onLeaveClick = (complaint) => {
    if (complaint) {
      setModalState(REPORT_MODAL_STATES.decline);
      setIsModalVisible(true);
      setComplaintId(complaint._id);
    }
  };

  const onPhotoItemClick = (photo, complaint) => () => {
    setSelectedPhoto({ url: photo.url });
    setModalState(REPORT_MODAL_STATES.viewPhoto);
    setIsModalVisible(true);
    setComplaintId(complaint);
  };

  return (
    <>
      <ReportsModal
        isVisible={isModalVisible}
        setIsVisible={setIsModalVisible}
        modalState={modalState}
        selectedPhoto={selectedPhoto}
        setSelectedPhoto={setSelectedPhoto}
        complaintId={complaintId}
        updateComplaints={updateComplaints}
        modalApproveInfoText="Вы уверены что хотите оставить этот комментарий?"
        modalDeleteInfoText="Вы уверены что хотите удалить этот комментарий? Отменить данное действие будет невозможно"
        subjectType="review"
      />

      {complaints.map((complaint, index) => (
        <div key={complaint._id} className="report_comments">
          <div className="report_item_review">
            <p className="report_item_review_title">{complaint.from_user?.display_name || ''}</p>
            <div className="report_item_review_rating">
              {generateStars(complaint.subject.rating)}
              <p className="report_item_review_rating_date">
                {moment(complaint.updatedAt).format('DD/MM/YYYY')}
              </p>
            </div>
            <p className="report_item_review_description">{complaint.subject.text}</p>
            <div className="report_item_review_gallery">
              {(complaint.subject.photos && complaint.subject.photos.length > 0) ? (
                complaint.subject.photos.map((photo, i) => (
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                  <img
                    key={i}
                    src={`${Api.url + photo.preview_url}`}
                    alt=""
                    className="report_item_review_gallery_image"
                    onClick={onPhotoItemClick(photo, complaint)}
                  />
                ))
              ) : null}
            </div>
          </div>

          <div className="report_item_info">
            <div className="report_item_info_block">
              <div className="report_item_info_block_first">
                <p className="report_item_info_title">{complaint.type === 'wrong' ? 'Недостоверная информация' : 'Оскорбительная информация'}</p>
                <p className="report_item_info_subtitle">Причина</p>
              </div>
              <div>
                <p className="report_item_info_title">
                  {moment(complaint.createdAt).format('DD/MM/YYYY')}
                </p>
                <p className="report_item_info_subtitle">Дата</p>
              </div>
            </div>
            <div>
              <p className="report_item_info_title">{complaint.subject.organization?.name || ''}</p>
              <p className="report_item_info_subtitle">Организация</p>
            </div>
            <div>
              <p className="report_item_info_title">{complaint.subject.from_user?.display_name || ''}</p>
              <p className="report_item_info_subtitle">Заявитель</p>
            </div>
          </div>
          <div className="report_buttons">
            <MyButton
              text="Оставить"
              secondary
              className="report_buttons_primary"
              onClick={() => onLeaveClick(complaint)}
            />
            <MyButton
              text="Удалить"
              className="report_buttons_secondary"
              onClick={() => onDeleteClick(complaint._id)}
            />
          </div>
        </div>
      ))}
    </>
  );
}

export default ReportComments;
