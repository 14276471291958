import {
  GET_COMPLAINTS_COUNT_SUCCESS,
  GET_COMPLAINTS_FAIL,
  GET_COMPLAINTS_REQUEST,
  GET_COMPLAINTS_SUCCESS,
  UPDATE_COMPLAINT_COUNT,
  UPDATE_COMPLAINTS,
} from '../actions/reports';

const initialState = {
  complaints: [],
  complaintCounts: {
    review: 0,
    photo: 0,
    info: 0,
    organization: 0,
  },
  complaintsLoading: false,
  allComplaintsCount: null,
};

const reportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPLAINTS_REQUEST:
      return {
        ...state,
        complaintsLoading: true,
      };
    case GET_COMPLAINTS_SUCCESS:
      return {
        ...state,
        complaints: action.payload.complaints,
        complaintCounts: {
          review: action.payload.reviews_count || 0,
          photo: action.payload.photos_count || 0,
          info: action.payload.infos_count || 0,
          organization: action.payload.organizations_count || 0,
        },
        complaintsLoading: false,
      };
    case GET_COMPLAINTS_FAIL:
      return {
        ...state,
        complaintsLoading: false,
      };

    case UPDATE_COMPLAINTS:
      const { complaintId } = action.payload;
      const updatedComplaints = state.complaints.filter(
        (complaint) => complaint._id !== complaintId,
      );

      return {
        ...state,
        complaints: updatedComplaints,
      };

    case UPDATE_COMPLAINT_COUNT:
      const { filterId } = action.payload;

      return {
        ...state,
        complaintCounts: {
          ...state.complaintCounts,
          [filterId]: state.complaintCounts[filterId] - 1,
        },
      };

    case GET_COMPLAINTS_COUNT_SUCCESS:
      return {
        ...state,
        allComplaintsCount: action.payload.complaints_count,
      };

    default:
      return state;
  }
};

export default reportsReducer;
