import { takeLatest, call, put } from 'redux-saga/effects';
import {
  LOGIN_REQUEST,
  loginSuccess,
  loginFailure,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAILURE,
} from '../actions/account';
import Api from '../../Api';
import LocalStorageServices from '../../helpers/LocalStorageServices';

export default function* watcher() {
  yield takeLatest(LOGIN_REQUEST, login);
  yield takeLatest(GET_PROFILE_REQUEST, getProfile);
}

function* login(action) {
  try {
    const { data } = yield call(Api.login, action.payload);

    let { user_data } = data;
    const { token } = data;

    const isAdmin = Boolean(user_data?._id);
    const isRoot = !user_data?._id;

    if (isRoot) user_data = { _id: 'some_root_id' };

    user_data.isAdmin = isAdmin;
    user_data.isRoot = isRoot;

    LocalStorageServices.setToken(token);
    LocalStorageServices.setProfile(user_data);
    yield put(loginSuccess({ token, profile: user_data }));
  } catch (error) {
    console.error('Login error:', error);

    let errorMessage = 'Unknown error';

    if (error.response) {
      errorMessage = error.response.data.message || 'Unknown server error';
    } else if (error.request) {
      errorMessage = 'No response from the server';
    } else {
      errorMessage = error.message || 'Unknown error';
    }

    yield put(loginFailure(errorMessage));
  }
}

function* getProfile(action) {
  try {
    const { data } = yield call(Api.getProfile);

    let { user_data } = data;

    const isAdmin = Boolean(user_data?._id);
    const isRoot = !user_data?._id;

    if (isRoot) user_data = { _id: 'some_root_id' };

    user_data.isAdmin = isAdmin;
    user_data.isRoot = isRoot;

    LocalStorageServices.setProfile(user_data);
    yield put({
      type: GET_PROFILE_SUCCESS,
      payload: { profile: user_data },
    });
  } catch (error) {
    console.error('Get profile error:', error);

    let errorMessage = 'Unknown error';

    if (error.response) {
      errorMessage = error.response.data.message || 'Unknown server error';
    } else if (error.request) {
      errorMessage = 'No response from the server';
    } else {
      errorMessage = error.message || 'Unknown error';
    }

    yield put({
      type: GET_PROFILE_FAILURE,
      payload: errorMessage,
    });
  }
}
