export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT = 'LOGOUT';

export const loginRequest = (data) => ({
  type: LOGIN_REQUEST,
  payload: data,
});

export const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAILURE = 'GET_PROFILE_FAILURE';

export const getProfileRequest = () => ({
  type: GET_PROFILE_REQUEST,
});

export const loginSuccess = (token) => ({
  type: LOGIN_SUCCESS,
  payload: token,
});

export const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  payload: error,
});

export const logout = () => ({
  type: LOGOUT,
});

export const SET_TOKEN = 'SET_TOKEN';
export const setToken = (token) => ({
  type: SET_TOKEN,
  payload: token,
});

export const SET_PROFILE = 'SET_PROFILE';
export const setProfile = (profile) => ({
  type: SET_PROFILE,
  payload: profile,
});

export const SET_ALLOCATED_COUNTRIES = 'SET_ALLOCATED_COUNTRIES';
export const setAllocatedCountries = (data) => ({
  type: SET_ALLOCATED_COUNTRIES,
  payload: data,
});

export const SET_SYSTEM_COUNTRY = 'SET_SYSTEM_COUNTRY';
export const setSystemCountry = (data) => ({
  type: SET_SYSTEM_COUNTRY,
  payload: data,
});
