function checkCoordinates(lat, lon) {
  return (
    Number(lat) >= -90 &&
    Number(lat) <= 90 &&
    Number(lon) >= -180 &&
    Number(lon) <= 180
  );
}

export default checkCoordinates;
