import React from 'react';
import ReactDOM from 'react-dom/client';
import Modal from 'react-modal';
import { Provider } from 'react-redux';
import store from './store';
import App from './App';
import './index.css';

Modal.setAppElement('#root');

const exclude = [
  'Warning: A component is `contentEditable` and contains `children` managed by React. It is now your responsibility to guarantee that none of those nodes are unexpectedly modified or duplicated. This is probably not intentional.%s',
];

const originalWarn = console.error;
console.error = (message, ...optionalParams) => {
  if (exclude.includes(message)) {
    return;
  }
  if (message) {
    originalWarn(message, ...optionalParams);
  }
};

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);
