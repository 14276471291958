export const ORGANIZATION_FILTERS = [
  { id: 'all', name: 'Все' },
  { id: 'true', name: 'Активные' },
  { id: 'false', name: 'Забаненные' },
];

export const ORGANIZATION_WIDTHS = [4, 42, 23, 23, 3]; // margin right

export const ORGANIZATION_HEADER_COLUMNS = [
  { text: '№', width: ORGANIZATION_WIDTHS[0] },
  { text: 'Название организации', width: ORGANIZATION_WIDTHS[1] },
  { text: 'ID организации', width: ORGANIZATION_WIDTHS[2] },
  { text: 'ID владельца', width: ORGANIZATION_WIDTHS[3] },
  { text: '', width: ORGANIZATION_WIDTHS[4] }];

export const ORGANIZATION_MODAL_STATES = {
  block: 'block',
  makeActive: 'makeActive',
  changeOwner: 'changeOwner',
  changeData: 'changeData',
};
