import './css/index.scss';
import React, { useState } from 'react';

function FormInput({
  wrapperClassName = '',
  className = '',

  innerRef,
  id,
  error,

  svgPath,
  svgActivePath,
  svgErrorPath,
  ...p
}) {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div className={`input_div ${wrapperClassName}`}>

      <div className="row">
        <div className="icon">
          <img src={error ? svgErrorPath : isFocused ? svgActivePath : svgPath} alt="" />
        </div>
        <input
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          className={`input ${error ? 'error_input' : isFocused ? 'focused_input' : ''} ${className}`}
          ref={innerRef}
          {...p}
        />
      </div>

      <p className="error">{error}</p>
    </div>
  );
}

export default FormInput;
