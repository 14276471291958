export const GET_ADMINS_REQUEST = 'GET_ADMINS_REQUEST';
export const GET_ADMINS_SUCCESS = 'GET_ADMINS_SUCCESS';
export const GET_ADMINS_FAIL = 'GET_ADMINS_FAIL';
export const getAdminsRequest = (data) => ({
  type: GET_ADMINS_REQUEST,
  payload: data,
});

export const ADD_ADMIN = 'ADD_ADMIN';

export const addAdmin = (data) => ({
  type: ADD_ADMIN,
  payload: data,
});

export const UPDATE_ADMIN = 'UPDATE_ADMIN';

export const updateAdmin = (data) => ({
  type: UPDATE_ADMIN,
  payload: data,
});

export const DELETE_ADMIN = 'DELETE_ADMIN';

export const deleteAdmin = (data) => ({
  type: DELETE_ADMIN,
  payload: data,
});
