import React from 'react';
import SearchInput from '../../../components/SearchInput';

function ContactsSection({ contacts, setContacts }) {
  const handleValue = (ev) => {
    setContacts((prev) => ({ ...prev, [ev.target.name]: ev.target.value }));
  };

  const handleCleaning = (field) => () => {
    setContacts((prev) => ({ ...prev, [field]: '' }));
  };

  return (
    <div>
      <SearchInput
        wrapperClassName="add_organization_container_input"
        withLeftIcon={false}
        placeholder="Телефон"
        name="phone_number"
        value={contacts.phone_number || ''}
        onChange={handleValue}
        labelText="1234567890"
        functionAtCleaning={handleCleaning('phone_number')}
        shouldValueConsiderAsFocus={false}
      />
      <SearchInput
        wrapperClassName="add_organization_container_input"
        withLeftIcon={false}
        placeholder="Instagram"
        name="instagram"
        value={contacts.instagram || ''}
        onChange={handleValue}
        labelText="nickname"
        functionAtCleaning={handleCleaning('instagram')}
        shouldValueConsiderAsFocus={false}
      />
      <SearchInput
        wrapperClassName="add_organization_container_input"
        withLeftIcon={false}
        placeholder="Telegram"
        name="telegram"
        value={contacts.telegram || ''}
        onChange={handleValue}
        labelText="nickname"
        functionAtCleaning={handleCleaning('telegram')}
        shouldValueConsiderAsFocus={false}
      />
      <SearchInput
        wrapperClassName="add_organization_container_input"
        withLeftIcon={false}
        placeholder="WhatsApp"
        name="whatsapp"
        value={contacts.whatsapp || ''}
        onChange={handleValue}
        labelText="1234567890"
        functionAtCleaning={handleCleaning('whatsapp')}
        shouldValueConsiderAsFocus={false}
      />
      <SearchInput
        wrapperClassName="add_organization_container_input"
        withLeftIcon={false}
        placeholder="Viber"
        name="viber"
        value={contacts.viber || ''}
        onChange={handleValue}
        labelText="1234567890"
        functionAtCleaning={handleCleaning('viber')}
        shouldValueConsiderAsFocus={false}
      />
      <SearchInput
        wrapperClassName="add_organization_container_input"
        withLeftIcon={false}
        placeholder="Facebook"
        name="facebook"
        value={contacts.facebook || ''}
        onChange={handleValue}
        labelText="https://www.facebook.com/profile"
        functionAtCleaning={handleCleaning('facebook')}
        shouldValueConsiderAsFocus={false}
      />
      <SearchInput
        wrapperClassName="add_organization_container_input"
        withLeftIcon={false}
        placeholder="Сайт"
        name="website"
        value={contacts.website || ''}
        onChange={handleValue}
        labelText="https://www.yourwebsite.example"
        functionAtCleaning={handleCleaning('website')}
        shouldValueConsiderAsFocus={false}
      />
    </div>
  );
}

export default ContactsSection;
