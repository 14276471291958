import React, { useEffect, useRef, useState } from 'react';
import './css/index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { set } from 'lodash';
import PageTitle from '../../components/PageTitle';
import { ARTICLES_MODAL_STATES, ARTICLES_PAGE_STATES } from './constants';
import InfoTable from '../../components/InfoTable';
import ArticlesViewBlock from './components/ArticlesViewBlock';
import ArticlesButtons from './components/ArticlesButtons';
import ArticlesEditBlock from './components/ArticlesEditBlock';
import useInputState from '../../helpers/hooks/useInputState';
import { setInfoTable } from '../../store/actions/staticData';
import sendRequest from '../../helpers/sendRequest';
import Api from '../../Api';
import ArticlesModal from './components/ArticlesModal';

function Articles(props) {
  const dispatch = useDispatch();
  const { systemCountry } = useSelector((store) => store.account);
  const { infoTable } = useSelector((store) => store.staticData);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalState, setModalState] = useState('');
  const [pageState, setPageState] = useState(ARTICLES_PAGE_STATES.view);
  const [selectedInfo, setSelectedInfo] = useState({});
  const [newInnerHtml, setNewInnerHtml] = useInputState('');

  const [savingLoading, setSavingLoading] = useState(false);

  const onInfoClick = (val) => {
    setSelectedInfo(val);
    setNewInnerHtml(val.inner_html, true);
  };

  const onInnerHtmlSave = async () => {
    const editedInfo = await editInfo(newInnerHtml, (selectedInfo.is_visible && Boolean(newInnerHtml)));
    if (!editedInfo?._id) return;
    setPageState(ARTICLES_PAGE_STATES.view);
    selectedInfo.inner_html = editedInfo.inner_html;
    dispatch(setInfoTable(infoTable));
  };

  const deleteInfo = async () => {
    const editedInfo = await editInfo('', false);
    if (!editedInfo?._id) return;
    setPageState(ARTICLES_PAGE_STATES.view);
    setNewInnerHtml('', true);
    selectedInfo.inner_html = '';
    selectedInfo.is_visible = false;
    dispatch(setInfoTable(infoTable));
  };

  const onToggleClick = async (info) => {
    info.is_visible = !info.is_visible;
    dispatch(setInfoTable(infoTable));
    await editInfo(info.inner_html, info.is_visible, info._id);
  };

  const editInfo = async (innerHtml, isVisible, informationItemId = selectedInfo._id) => {
    const payload = {
      country_id: systemCountry._id,
      information_item_id: informationItemId,
      inner_html: innerHtml,
      is_visible: isVisible,
    };

    const data = await sendRequest({
      request: Api.editInfoItem,
      payload,
      warnErrorText: 'while editing info item',
      setLoading: setSavingLoading,
    });

    if (data?.status !== 'ok') return;

    return data.info;
  };

  const onCancelClick = () => {
    setNewInnerHtml(selectedInfo.inner_html, true);
    setPageState(ARTICLES_PAGE_STATES.view);
  };

  const onDeleteClick = () => {
    setModalState(ARTICLES_MODAL_STATES.delete);
    setIsModalVisible(true);
  };

  useEffect(() => {
    if (infoTable?.length) {
      onInfoClick(infoTable[0].items[0]);
    }
  }, [infoTable]);

  return (
    <div className="articles_wrapper">
      {pageState === ARTICLES_PAGE_STATES.view && (
        <PageTitle text="Статьи" />
      )}

      <div className="articles_container">
        {pageState === ARTICLES_PAGE_STATES.view && (
        <div className="articles_list">
          <InfoTable
            selectedInfo={selectedInfo}
            setSelectedInfo={setSelectedInfo}
            onClick={onInfoClick}
            withToggle
            onToggleClick={onToggleClick}
          />
        </div>
        )}

        {pageState !== ARTICLES_PAGE_STATES.editing ? (
          <ArticlesViewBlock
            innerHtml={newInnerHtml}
          />
        ) : (
          <ArticlesEditBlock
            innerHtml={newInnerHtml}
            setInnerHtml={setNewInnerHtml}
          />
        )}

        <ArticlesButtons
          pageState={pageState}
          setPageState={setPageState}
          onDeleteClick={onDeleteClick}
          onInnerHtmlSave={onInnerHtmlSave}
          onCancelClick={onCancelClick}
          savingLoading={savingLoading}
        />
      </div>

      <ArticlesModal
        isVisible={isModalVisible}
        setIsVisible={setIsModalVisible}
        modalState={modalState}
        selectedInfo={selectedInfo}
        onDelete={deleteInfo}
      />
    </div>
  );
}

export default Articles;
