import React from 'react';
import SearchInput from '../../../components/SearchInput';

export const days = [
  { name: 'Понедельник', value: 'monday' },
  { name: 'Вторник', value: 'tuesday' },
  { name: 'Среда', value: 'wednesday' },
  { name: 'Четверг', value: 'thursday' },
  { name: 'Пятница', value: 'friday' },
  { name: 'Суббота', value: 'saturday' },
  { name: 'Воскресенье', value: 'sunday' },
];
const dayTypes = {
  dayOff: {
    value: 'day_off',
    name: 'выходной',
  },
  tempClosed: {
    value: 'temp_closed',
    name: 'врем. закрыто',
  },
  wholeDay: {
    value: 'whole_day',
    name: 'круглосуточно',
  },
  hours: {
    value: 'hours',
    name: 'часы',
    hide: true,
  },
};
function DaysSection({ setSchedule, schedule }) {
  const handleTimeChange = (day, type, value) => {
    setSchedule((prev) => ({
      ...prev,
      [day]: { ...prev[day], type: dayTypes.hours.value, [type]: value },
    }));
  };

  const handleDayTypeChange = (day, selectedType) => {
    setSchedule((prev) => ({
      ...prev,
      [day]: { start: '', end: '', type: selectedType },
    }));
  };

  return (
    <div className="schedule_section">
      {days.map((day, index) => (
        <div key={day.value} className="schedule_section_row">
          <div className="schedule_section_row_box">
            <div className="schedule_section_row_day">{day.name}</div>
            <div className="schedule_section_row_time">
              <SearchInput
                wrapperClassName="schedule_section_row_time_wrapper_input"
                className="schedule_section_row_time_input"
                withLeftIcon={false}
                withCloseIcon={false}
                placeholder="С"
                value={schedule[day.value].start}
                onChange={(value) => handleTimeChange(day.value, 'start', value)}
                isTimeInput
                shouldValueConsiderAsFocus={false}
              />
              <SearchInput
                wrapperClassName="schedule_section_row_time_wrapper_input"
                className="schedule_section_row_time_input"
                withLeftIcon={false}
                withCloseIcon={false}
                placeholder="До"
                value={schedule[day.value].end}
                onChange={(value) => handleTimeChange(day.value, 'end', value)}
                isTimeInput
                shouldValueConsiderAsFocus={false}
              />
            </div>
          </div>

          <div className="schedule_section_row_tag">
            {Object.keys(dayTypes).map((key) => (
              !dayTypes[key].hide && (
                <div
                  key={key}
                  className={`schedule_section_row_tag_item ${schedule[day.value].type === dayTypes[key].value ? 'pressed' : ''}`}
                  onClick={() => handleDayTypeChange(day.value, dayTypes[key].value)}
                >
                  {dayTypes[key].name}
                </div>
              )
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default DaysSection;
