import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import MyButton from '../../../components/MyButton';
import MyCheckBox from '../../../components/MyCheckbox';

function SelectSubCategories({
  setValue, setTempComponentState, selectedCategory, initValue = [],
}) {
  const [selected, setSelected] = useState(initValue);
  const handleClick = (item) => {
    if (selected.includes(item)) {
      setSelected((prev) => prev.filter((el) => el !== item));
    } else {
      setSelected((prev) => [...prev, item]);
    }
  };

  const handleSubmit = () => {
    setValue(selected);
    setTempComponentState(null);
  };

  return (
    <div className="temp_container">
      <p className="temp_container_title">Выберите подкатегории</p>
      <div className="temp_container_scroll with_button">
        {selectedCategory.sub_categories.map((item) => (
          <MyCheckBox
            key={item._id}
            value={selected.includes(item)}
            onClick={() => handleClick(item)}
            text={item.name}
            wrapperClassName="temp_container_item"
          />
        ))}
      </div>

      <MyButton onClick={handleSubmit} text="Ок" className="temp_container_button" />
    </div>
  );
}

export default SelectSubCategories;
