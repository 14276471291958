const typesToExclude = ['string'];

function getFormData(object, images = []) {
  const formData = new FormData();
  Object.keys(object).forEach((key) => {
    if (typesToExclude.includes(typeof object[key])) {
      formData.append(key, object[key]);
    } else {
      formData.append(key, JSON.stringify(object[key]));
    }
  });

  images.forEach((image) => {
    if (image.file) {
      formData.append('image', image.file);
    } else {
      formData.append('image', image);
    }
  });

  return formData;
}

export default getFormData;
