import React from 'react';
import './css/index.scss';

function TableHeader({ columns }) {
  return (
    <div className="table_header">
      {columns.map((item, index) => (
        <p key={index} className="table_cell" style={{ width: `${item.width}%` }}>{item.text}</p>
      ))}
    </div>
  );
}

export default TableHeader;
