class PasswordServices {
  static salt = 'asdhfka;hfjakgd89yeg12lkdf';

  static cipher = (salt = this.salt) => {
    const textToChars = (text) => text.split('').map((c) => c.charCodeAt(0));
    const byteHex = (n) => (`0${Number(n).toString(16)}`).substr(-2);
    const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);

    return (text) => text.split('')
      .map(textToChars)
      .map(applySaltToChar)
      .map(byteHex)
      .join('');
  };

  static decipher = (salt = this.salt) => {
    const textToChars = (text) => text.split('').map((c) => c.charCodeAt(0));
    const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
    return (encoded) => encoded.match(/.{1,2}/g)
      .map((hex) => parseInt(hex, 16))
      .map(applySaltToChar)
      .map((charCode) => String.fromCharCode(charCode))
      .join('');
  };

  static encryptPassword = this.cipher();

  static decryptPassword = this.decipher();
}
export default PasswordServices;
