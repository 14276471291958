import React, { useEffect, useState } from 'react';
import './css/index.scss';
import { useDispatch, useSelector } from 'react-redux';
import PageTitle from '../../components/PageTitle';
import MyButton from '../../components/MyButton';
import addAdminIcon from '../../assets/formIcons/addAdmin.svg';
import SearchInput from '../../components/SearchInput';
import useInputState from '../../helpers/hooks/useInputState';
import CountryItem from './components/CountryItem';
import CountryInfo from './components/CountryInfo';
import getFormData from '../../helpers/getFormData';
import sendRequest from '../../helpers/sendRequest';
import Api from '../../Api';
import { addCountry, updateCountry } from '../../store/actions/staticData';
import checkIfErrorExists from '../../helpers/checkIfErrorExists';
import ERRORS, { ERROR_TYPES } from '../../helpers/errors';

function Cities() {
  const dispatch = useDispatch();
  const [q, setQ] = useInputState();
  const { countries } = useSelector((store) => store.staticData);
  const [selectedCountry, setSelectedCountry] = useState({});
  const [isInit, setIsInit] = useState(false);

  const onAddCountryClick = async () => {
    const payload = { name: 'Название', flag_name: 'Название' };

    const formData = getFormData(payload);

    const data = await sendRequest({
      request: Api.addCountry,
      payload: formData,
      warnErrorText: 'while adding country',
    });

    if (data?.status === 'ok') {
      dispatch(addCountry(data.country));
      setSelectedCountry(data.country);
    }
  };

  const onCountryClick = (country) => () => {
    if (selectedCountry !== country) {
      setSelectedCountry(country);
    }
  };

  useEffect(() => {
    if (!isInit && countries?.length) {
      setSelectedCountry(countries[0]);
      setIsInit(true);
    }
  }, [countries, isInit]);

  return (
    <div>
      <PageTitle
        text="Города"
        RightComponent={(
          <div className="countries_top_row">
            <div className="countries_top_input">
              <SearchInput
                value={q}
                onChange={setQ}
                placeholder="Поиск"
                disabled={!selectedCountry._id}
              />
            </div>

            <MyButton
              text="Добавить страну"
              onClick={onAddCountryClick}
              className="countries_add_btn"
            />
          </div>
        )}
      />

      <div className="countries_wrapper">
        <ul className="countries_scroll">
          {countries?.length ? (
            countries.map(
              (country) => (
                <CountryItem
                  key={country._id}
                  country={country}
                  onClick={onCountryClick(country)}
                  isSelected={selectedCountry._id === country._id}
                />
              ),
            )
          ) : (
            <CountryItem
              country={{ name: 'Название' }}
              isSelected
            />
          )}
        </ul>
        <div className="cities_table">
          <CountryInfo
            selectedCountry={selectedCountry}
            setSelectedCountry={setSelectedCountry}
            countries={countries}
            q={q}
            setQ={setQ}
          />
        </div>
      </div>
    </div>
  );
}

export default Cities;
