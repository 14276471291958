import { all, fork } from 'redux-saga/effects';
import account from './account';
import organizations from './organizations';
import users from './users';
import admins from './admins';
import staticData from './staticData';
import reports from './reports';

export default function* watchers() {
  yield all([
    account,
    organizations,
    users,
    admins,
    staticData,
    reports,
  ].map(fork));
}
