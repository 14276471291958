import { takeLatest, call, put } from 'redux-saga/effects';
import Api from '../../Api';
import { GET_ORGANIZATIONS_FAIL, GET_ORGANIZATIONS_REQUEST, GET_ORGANIZATIONS_SUCCESS } from '../actions/organizations';

export default function* watcher() {
  yield takeLatest(GET_ORGANIZATIONS_REQUEST, getOrganizations);
}

function* getOrganizations(action) {
  try {
    const { data } = yield call(Api.getOrganizations, action.payload);

    yield put({
      type: GET_ORGANIZATIONS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.warn(error, 'while getting organizations');

    let errorMessage = 'Unknown error';

    if (error.response) {
      errorMessage = error.response.data.message || 'Unknown server error';
    } else if (error.request) {
      errorMessage = 'No response from the server';
    } else {
      errorMessage = error.message || 'Unknown error';
    }

    yield put({
      type: GET_ORGANIZATIONS_FAIL,
      payload: errorMessage,
    });
  }
}
