import React, { useState } from 'react';
import './css/index.scss';
import { useNavigate } from 'react-router-dom';

function CopyTag({
  tagName, label, copyText, navigateTo, copyWithNewLine = true,
}) {
  const navigate = useNavigate();
  const [wasCopied, setWasCopied] = useState(false);
  const onClick = () => {
    if (wasCopied) return;
    if (navigateTo) {
      window.open(navigateTo);
      return;
    }

    setWasCopied(true);
    if (copyWithNewLine) {
      navigator.clipboard.writeText(`${copyText}\n`);
    } else {
      navigator.clipboard.writeText(copyText);
    }

    setTimeout(() => {
      setWasCopied(false);
    }, 1200);
  };

  return (
    <div className="copy_tag_wrapper">
      <div className={`copy_tag_button ${wasCopied && 'was_copied'}`} onClick={onClick}>{wasCopied ? '!!' : tagName}</div>
      <p className="copy_tag_text">{label}</p>
    </div>
  );
}

export default CopyTag;
