export default (count, wordsList) => {
  if (!isFinite(Number(count))) {
    return '';
  }
  const countString = count.toString();
  if (countString.includes('.')) {
    return wordsList[1];
  }
  if (count % 10 === 1) {
    return wordsList[0];
  } if (countString[0] !== '1' && [2, 3, 4].includes(count % 10)) {
    return wordsList[1];
  }
  return wordsList[2];
};

export const WORDS_LIST = {
  tag: ['тэг', 'тэга', 'тэгов'],
  photo: ['фото', 'фото', 'фото'],
};
