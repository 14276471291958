import React from 'react';
import './css/index.scss';
import TableRow from '../../../../components/TableRow';
import TableCell from '../../../../components/TableCell';
import { COUNTRY_INFO_WIDTH, DELETING_MODAL_STATES } from '../../constants';
import TableInput from '../../../../components/TableInput';
import DotsIcon from '../../../../assets/formIcons/dots.svg';
import useComponentVisible from '../../../../helpers/hooks/useComponentVisible';
import BlockIcon from '../../../../assets/formIcons/block.svg';
import EditIcon from '../../../../assets/formIcons/edit.svg';

function CityItem({
  city, setCities, cities, setModalState, setIsModalVisible, setSelectedCity, setQ,
}) {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const handleFiled = (ev) => {
    const field = ev.target.getAttribute('data-field');
    const secondField = ev.target.getAttribute('data-second-field');
    if (secondField) {
      city[field][secondField] = ev.target.value;
    } else {
      city[field] = ev.target.value;
    }
    setCities([...cities]);
  };

  const onDeleteCity = () => {
    setIsComponentVisible(false);
    setSelectedCity(city);
    setModalState(DELETING_MODAL_STATES.city);
    setIsModalVisible(true);
  };

  const onEditCity = () => {
    setQ('', true);
    city.isEditing = true;
    setCities([...cities]);
    setIsComponentVisible(false);
  };

  return (

    <TableRow>
      {city.isEditing
        ? (
          <>
            <TableCell width={COUNTRY_INFO_WIDTH[0]}>
              <TableInput
                withLeftIcon={false}
                placeholder="Название"
                data-field="name"
                value={city.name}
                onChange={handleFiled}
              />
            </TableCell>
            <TableCell width={COUNTRY_INFO_WIDTH[1]}>
              <TableInput
                withLeftIcon={false}
                placeholder="Широта"
                data-field="coordinates"
                data-second-field="lat"
                value={city.coordinates.lat}
                onChange={handleFiled}
                type="number"
              />
            </TableCell>
            <TableCell width={COUNTRY_INFO_WIDTH[2]}>
              <TableInput
                withLeftIcon={false}
                placeholder="Долгота"
                data-field="coordinates"
                data-second-field="lon"
                value={city.coordinates.lon}
                onChange={handleFiled}
                type="number"
              />
            </TableCell>
          </>
        )
        : (
          <>
            <TableCell width={COUNTRY_INFO_WIDTH[0]}>
              {city.name}
            </TableCell>
            <TableCell width={COUNTRY_INFO_WIDTH[1]}>
              {city.coordinates.lat}
            </TableCell>
            <TableCell width={COUNTRY_INFO_WIDTH[2]}>
              {city.coordinates.lon}
            </TableCell>
          </>
        )}
      <TableCell
        width={COUNTRY_INFO_WIDTH[3]}
        isIcon
        onClick={() => setIsComponentVisible(true)}
      >
        <img src={DotsIcon} alt="" />
      </TableCell>

      <div ref={ref} className={`table_popup ${isComponentVisible && 'visible'}`}>

        <div className="table_popup_item" onClick={onDeleteCity}>
          <img src={BlockIcon} alt="" />
          <p>Удалить город</p>
        </div>

        {!city.isEditing && (
        <div className="table_popup_item" onClick={onEditCity}>
          <img src={EditIcon} alt="" />
          <p>Изменить данные</p>
        </div>
        )}
      </div>
    </TableRow>

  );
}

export default CityItem;
