import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PageTitle from '../../components/PageTitle';
import useInputState from '../../helpers/hooks/useInputState';
import { getOrganizationsRequest } from '../../store/actions/organizations';
import OrganizationItem from './components/OrganizationItem';
import OrganizationModal from './components/OrganizationModal';
import { ORGANIZATION_FILTERS, ORGANIZATION_HEADER_COLUMNS, ORGANIZATION_WIDTHS } from './constants';
import MyButton from '../../components/MyButton';
import AddOrgIcon from '../../assets/formIcons/addOrg.svg';
import MyTable from '../../components/MyTable';

function Organizations() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    organizations, organizationsTotal, organizationsLoading, organizationsError,
  } = useSelector((store) => store.organizations);

  const [page, setPage] = useInputState(1);
  const [perPage, setPerPage] = useState(25);
  const [selectedFilter, setSelectedFilter] = useState(ORGANIZATION_FILTERS[0]);
  const [q, setQ] = useInputState();
  const [isInit, setIsInit] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalState, setModalState] = useState('');
  const [selectedOrganization, setSelectedOrganization] = useState({});
  const [itemsPassed, setItemsPassed] = useState(0);

  const getOrganizations = ({ paramsPage, paramsPerPage = perPage, isInitRequired = true }) => {
    if (organizationsLoading || (isInitRequired && !isInit)) return;
    dispatch(getOrganizationsRequest({
      q,
      page: paramsPage,
      per_page: paramsPerPage,
      is_active: selectedFilter.id,
      should_add_views: 'false',
    }));
  };

  const TableItemComponent = useCallback((item, index) => (
    <OrganizationItem
      key={item._id}
      item={item}
      itemsPassed={itemsPassed}
      widths={ORGANIZATION_WIDTHS}
      index={index}
      setSelectedOrganization={setSelectedOrganization}
      setModalState={setModalState}
      setIsModalVisible={setIsModalVisible}
    />
  ), [itemsPassed]);

  const handlePage = (newVal) => {
    if (newVal === page) return;
    setPage(newVal);
    getOrganizations({ paramsPage: newVal });
  };

  const onCreateOrganizationClick = () => {
    navigate('/organizations/create');
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setPage(1);

      if (perPage < 1) {
        setPerPage(1);
        getOrganizations({ paramsPage: 1, paramsPerPage: 1 });
      } else if (perPage > 500) {
        setPerPage(500);
        getOrganizations({ paramsPage: 1, paramsPerPage: 500 });
      } else {
        getOrganizations({ paramsPage: 1 });
      }
    }, 600);

    return () => clearTimeout(timer);
  }, [perPage]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setPage(1);
      getOrganizations({ paramsPage: 1 });
    }, 400);

    return () => clearTimeout(timer);
  }, [q]);

  useEffect(() => {
    setPage(1);
    getOrganizations({ paramsPage: 1 });
  }, [selectedFilter]);

  useEffect(() => {
    getOrganizations({ paramsPage: page, isInitRequired: false });
    setTimeout(() => {
      setIsInit(true);
    }, 500);
  }, []);

  useEffect(() => {
    setItemsPassed((page - 1) * perPage);
  }, [organizations]);

  return (
    <div>
      <PageTitle
        text="Организации"
        RightComponent={(
          <div className="organizations_table">
            <MyButton
              text="Добавить"
              svgPath={AddOrgIcon}
              className="org_add_btn"
              onClick={onCreateOrganizationClick}
            />
          </div>
        )}
      />
      <MyTable
        q={q}
        setQ={setQ}
        filters={ORGANIZATION_FILTERS}
        perPage={perPage}
        setPerPage={setPerPage}
        selectedFilter={selectedFilter}
        setSelectedFilter={setSelectedFilter}
        headerColumns={ORGANIZATION_HEADER_COLUMNS}
        Item={TableItemComponent}
        data={organizations}
        page={page}
        setPage={handlePage}
        total={organizationsTotal}
      />

      <OrganizationModal
        selectedOrganization={selectedOrganization}
        modalState={modalState}
        isVisible={isModalVisible}
        setIsVisible={setIsModalVisible}
        setSelectedOrganization={setSelectedOrganization}
      />
    </div>
  );
}

export default Organizations;
