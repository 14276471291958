import { call, put, takeLatest } from 'redux-saga/effects';
import Api from '../../Api';
import {
  GET_CATEGORIES_REQUEST, GET_CATEGORIES_SUCCESS,
  GET_COUNTRIES_FAIL,
  GET_COUNTRIES_REQUEST,
  GET_COUNTRIES_SUCCESS,
  GET_INFO_TABLE_FAIL,
  GET_INFO_TABLE_REQUEST,
  GET_INFO_TABLE_SUCCESS, GET_LANGUAGES_REQUEST, GET_LANGUAGES_SUCCESS,
} from '../actions/staticData';

export default function* watcher() {
  yield takeLatest(GET_COUNTRIES_REQUEST, getCountries);
  yield takeLatest(GET_INFO_TABLE_REQUEST, getInfoTable);
  yield takeLatest(GET_CATEGORIES_REQUEST, getCategories);
  yield takeLatest(GET_LANGUAGES_REQUEST, getLanguages);
}

function* getCountries(action) {
  try {
    const { data } = yield call(Api.getCountries, action.payload);

    yield put({
      type: GET_COUNTRIES_SUCCESS,
      payload: data.countries,
    });
  } catch (error) {
    console.warn(error, 'while getting countries');

    let errorMessage;

    if (error.response) {
      errorMessage = error.response.data.message || 'Unknown server error';
    } else if (error.request) {
      errorMessage = 'No response from the server';
    } else {
      errorMessage = error.message || 'Unknown error';
    }

    yield put({
      type: GET_COUNTRIES_FAIL,
      payload: errorMessage,
    });
  }
}

function* getCategories(action) {
  try {
    const { data } = yield call(Api.getCategories, action.payload);

    yield put({
      type: GET_CATEGORIES_SUCCESS,
      payload: data.categories,
    });
  } catch (error) {
    console.warn(error, 'while getting categories');
  }
}

function* getLanguages(action) {
  try {
    const { data } = yield call(Api.getLanguages, action.payload);

    yield put({
      type: GET_LANGUAGES_SUCCESS,
      payload: data.languages,
    });
  } catch (e) {
    console.warn(e, 'while getting languages');
  }
}

function* getInfoTable(action) {
  try {
    const { data } = yield call(Api.getInfoTable, action.payload);

    yield put({
      type: GET_INFO_TABLE_SUCCESS,
      payload: data.info_table,
    });
  } catch (error) {
    console.warn(error, 'while getting info table');

    let errorMessage;

    if (error.response) {
      errorMessage = error.response.data.message || 'Unknown server error';
    } else if (error.request) {
      errorMessage = 'No response from the server';
    } else {
      errorMessage = error.message || 'Unknown error';
    }

    yield put({
      type: GET_INFO_TABLE_FAIL,
      payload: errorMessage,
    });
  }
}
