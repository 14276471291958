import {
  ADD_ADMIN, DELETE_ADMIN,
  GET_ADMINS_FAIL, GET_ADMINS_REQUEST, GET_ADMINS_SUCCESS, UPDATE_ADMIN,
} from '../actions/admins';

const initialState = {
  admins: [],
  adminsTotal: 0,
  adminsLoading: false,
  adminsError: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_ADMINS_REQUEST: {
      return {
        ...state,
        adminsLoading: true,
        adminsError: false,
      };
    }
    case GET_ADMINS_SUCCESS: {
      return {
        ...state,
        admins: action.payload.admins,
        adminsTotal: action.payload.total,
        adminsLoading: false,
        adminsError: false,
      };
    }
    case GET_ADMINS_FAIL: {
      return {
        ...state,
        adminsLoading: false,
        adminsError: true,
      };
    }
    case ADD_ADMIN: {
      return {
        ...state,
        adminsLoading: false,
        adminsError: false,
        admins: [action.payload, ...state.admins],
      };
    }
    case UPDATE_ADMIN: {
      const updatedAdmins = state.admins.map(
        (item) => (item._id === action.payload._id ? Object.assign(item, action.payload) : item),
      );
      return {
        ...state,
        adminsLoading: false,
        adminsError: false,
        admins: updatedAdmins,
      };
    }
    case DELETE_ADMIN: {
      const filteredAdmins = state.admins.filter((item) => (item._id !== action.payload._id));
      return {
        ...state,
        adminsLoading: false,
        adminsError: false,
        admins: filteredAdmins,
      };
    }

    default: {
      return state;
    }
  }
}
