import React, { useEffect, useRef, useState } from 'react';
import './css/index.scss';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import SearchInput from '../../../../components/SearchInput';
import useInputState from '../../../../helpers/hooks/useInputState';
import IconButton from '../../../../components/IconButton';
import FlagIcon from '../../../../assets/formIcons/flags.svg';
import DeleteIcon from '../../../../assets/formIcons/delete.svg';
import CitiesIcon from '../../../../assets/formIcons/cities.svg';
import SaveIcon from '../../../../assets/formIcons/save.svg';
import TableHeader from '../../../../components/TableHeader';
import { COUNTRY_INFO_HEADER_COLUMNS, DELETING_MODAL_STATES } from '../../constants';
import Api from '../../../../Api';
import sendRequest from '../../../../helpers/sendRequest';
import CityItem from '../CityItem';
import getFormData from '../../../../helpers/getFormData';
import { updateCountry } from '../../../../store/actions/staticData';
import checkIfErrorExists from '../../../../helpers/checkIfErrorExists';
import ERRORS, { ERROR_TYPES } from '../../../../helpers/errors';
import DeletingModal from '../DeletingModal';
import convertStringToSearchable from '../../../../helpers/convertStringToSearchable';
import { getProfileRequest } from '../../../../store/actions/account';

function CountryInfo({
  selectedCountry, setSelectedCountry, countries, q, setQ,
}) {
  const dispatch = useDispatch();
  const selectImageInput = useRef();
  const [selectedCity, setSelectedCity] = useState({});

  const [name, setName, nameError, setNameError] = useInputState('');
  const [flag, setFlag] = useState('');
  const [newFlag, setNewFlag] = useState({});
  const [cities, setCities] = useState([]);
  const [filteredCities, setFilteredCities] = useState([]);

  const [modalState, setModalState] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [savingLoading, setSavingLoading] = useState(false);
  const [addingCityLoading, setAddingCityLoading] = useState(false);

  const onFlagClick = () => {
    if (typeof selectImageInput.current?.click !== 'function') return;
    selectImageInput.current.click();
  };

  const onDeleteClick = () => {
    setModalState(DELETING_MODAL_STATES.country);
    setIsModalVisible(true);
  };

  const onCitiesClick = async () => {
    if (!selectedCountry._id) return;
    const payload = {
      country_id: selectedCountry._id,
      name: 'Название города',
      lat: '12.345',
      lon: '12.345',
    };

    const data = await sendRequest({
      request: Api.addCity,
      payload,
      warnErrorText: 'while creating city',
      setLoading: setAddingCityLoading,
    });

    if (data?.status !== 'ok') return;

    setCities((prev) => [{ ...data.city, isEditing: true }, ...prev]);
  };

  const onFlagChange = (ev) => {
    if (!ev.target?.files?.length) return;

    setNewFlag(ev.target.files[0]);
    setFlag(URL.createObjectURL(ev.target.files[0]));
  };

  const onSaveClick = async () => {
    setSavingLoading(true);
    await editCountry();
    await editCities();
    setSavingLoading(false);
  };

  const editCountry = async () => {
    const payload = { name };
    const images = [newFlag];

    const formData = getFormData(payload, images);

    const data = await sendRequest({
      request: Api.editCountry,
      payload: { id: selectedCountry._id, formData },
      warnErrorText: 'while editing country',
    });

    if (data?.status === 'ok') {
      dispatch(updateCountry(data.country));
      dispatch(getProfileRequest()); // to update country in sidebar
    } else if (checkIfErrorExists(data.message, ERROR_TYPES.name)) {
      setNameError(ERRORS[data.message].text);
    }
  };

  const editCities = async () => {
    const newCities = cities.filter((item) => item.isEditing);

    const data = await sendRequest({
      request: Api.editCities,
      payload: { cities: newCities },
      warnErrorText: 'while editing cities',
    });

    if (data?.status === 'ok') {
      setCities(
        (prev) => _.unionBy(data.cities, prev, '_id')
          .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
      );
    }
  };

  const getCities = async () => {
    const data = await sendRequest({
      request: Api.getCities,
      payload: { country_id: selectedCountry._id, sort: 'creation' },
      warnErrorText: 'while getting cities',
    });

    if (data?.status !== 'ok') return;

    setCities(data.cities);
  };

  useEffect(() => {
    if (selectedCountry?._id) {
      setName(selectedCountry.name);
      setFlag(Api.url + selectedCountry.flag_url);
      setNewFlag({});
      getCities().then();
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (!q) {
      setFilteredCities(cities);
    } else {
      setFilteredCities(cities.filter(
        (city) => convertStringToSearchable(city.name).includes(convertStringToSearchable(q)),
      ));
    }
  }, [cities, q]);

  return (
    <div className="country_info_wrapper">
      <div className="country_info_top">
        <div className="country_info_left">
          <SearchInput
            placeholder="Название"
            value={name}
            onChange={setName}
            error={nameError}
            withLeftIcon={false}
            shouldValueConsiderAsFocus={false}
            disabled={!selectedCountry._id}
          />

          <IconButton
            svgPath={selectedCountry._id ? flag : FlagIcon}
            isPng
            onClick={onFlagClick}
            wrapperClassName="flag_icon"
            loading={!selectedCountry._id}
          />

          <input
            className="hidden_input"
            type="file"
            ref={selectImageInput}
            accept="image/png, image/jpeg"
            onChange={onFlagChange}
          />
        </div>

        <div className="country_info_right">
          <IconButton
            svgPath={DeleteIcon}
            onClick={onDeleteClick}
            loading={!selectedCountry._id}
            tooltipText="Удалить"
          />
          <IconButton
            svgPath={CitiesIcon}
            onClick={onCitiesClick}
            loading={!selectedCountry._id || addingCityLoading}
            tooltipText="Добавить город"
          />
          <IconButton
            svgPath={SaveIcon}
            onClick={onSaveClick}
            loading={!selectedCountry._id || savingLoading}
            tooltipText="Сохранить"
          />
        </div>
      </div>

      <div className="country_info_table_part">
        <TableHeader columns={COUNTRY_INFO_HEADER_COLUMNS} />

        {filteredCities.map((city) => (
          <CityItem
            key={city._id}
            city={city}
            setQ={setQ}
            cities={cities}
            setCities={setCities}
            setModalState={setModalState}
            setSelectedCity={setSelectedCity}
            setIsModalVisible={setIsModalVisible}
          />
        ))}
      </div>

      <DeletingModal
        isVisible={isModalVisible}
        setIsVisible={setIsModalVisible}
        modalState={modalState}
        selectedCity={selectedCity}
        setSelectedCity={setSelectedCity}
        selectedCountry={selectedCountry}
        setSelectedCountry={setSelectedCountry}
        countries={countries}
        setCities={setCities}
      />
    </div>
  );
}

export default CountryInfo;
