import React, { useEffect, useMemo, useState } from 'react';
import './css/index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useInputState from '../../helpers/hooks/useInputState';
import PageTitle from '../../components/PageTitle';
import MyButton from '../../components/MyButton';
import SearchInput from '../../components/SearchInput';
import SelectButton from '../../components/SelectButton';
import SelectCategory from './components/SelectCategory';
import { getCategoriesRequest, getLanguagesRequest } from '../../store/actions/staticData';
import SelectSubCategories from './components/SelectSubCategories';
import SelectCity from './components/SelectCity';
import MapView from './components/MapView';
import DaysSection, { days } from './components/DaysSection';
import ContactsSection from './components/ContactsSection';
import getNameToShowOfMany from '../../helpers/getNameToShowOfMany';
import SelectLanguages from './components/SelectLanguages';
import SelectTags from './components/SelectTags';
import { WORDS_LIST } from '../../helpers/getWordForm';
import SelectPhotos from './components/SelectPhotos';
import checkAddOrganizationPayload from '../../helpers/checkAddOrganizationPayload';
import MyModal from '../../components/MyModal';
import sendRequest from '../../helpers/sendRequest';
import Api from '../../Api';

const defaultSchedule = days.reduce((acc, day) => ({ ...acc, [day.value]: { start: '', end: '' } }), {});
const tempComponentStates = {
  categories: 'categories', subCategories: 'subCategories', city: 'city', languages: 'languages', tags: 'tags', photos: 'photos',
};

function AddOrganization() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { categories: categoriesFromStore, languages: languagesFromStore } = useSelector((store) => store.staticData);
  const [searchParams] = useSearchParams();
  const organizationId = searchParams.get('id');
  const isEditing = Boolean(organizationId);

  const [gettingLoading, setGettingLoading] = useState(isEditing);
  const [gettingError, setGettingError] = useState('');

  const [ownerId, setOwnerId] = useInputState();
  const [name, setName] = useInputState();
  const [category, setCategory] = useState(null);
  const [subCategories, setSubCategories] = useState([]);
  const [specialization, setSpecialization] = useInputState();
  const [city, setCity] = useState(null);
  const [address, setAddress] = useInputState();
  const [coordinates, setCoordinates] = useState(null);
  const [schedule, setSchedule] = useState(defaultSchedule);
  const [contacts, setContacts] = useState({});
  const [description, setDescription] = useInputState();
  const [languages, setLanguages] = useState([]);
  const [tags, setTags] = useState([]);
  const [photos, setPhotos] = useState([]);

  const [tempComponentState, setTempComponentState] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalText, setModalText] = useState('');
  const [modalState, setModalState] = useState('');
  const [loading, setLoading] = useState(false);

  const subCategoriesName = useMemo(
    () => getNameToShowOfMany({ list: subCategories }),
    [subCategories],
  );
  const languagesName = useMemo(
    () => getNameToShowOfMany({ list: languages }),
    [languages],
  );
  const tagsName = useMemo(
    () => getNameToShowOfMany({
      list: tags, field: 'self', words: WORDS_LIST.tag, firstWord: '',
    }),
    [tags],
  );

  const onCancelClick = () => {
    navigate('/organizations/view');
  };

  const onSave = async () => {
    const convertedSchedule = Object.keys(schedule)
      .reduce((acc, day) => ({
        ...acc,
        [day]: schedule[day].type === 'hours' ? `${schedule[day].start}-${schedule[day].end}` : schedule[day].type,
      }), {});

    const { errors, formData, payload } = checkAddOrganizationPayload({
      owner_id: ownerId,
      name,
      category_id: category?._id,
      sub_category_ids: subCategories.map((i) => i._id),
      must_set_subcategories: !!category?.sub_categories?.length,
      country_id: city?.country_id,
      city_id: city?._id,
      specialization,
      address,
      coordinates,
      workTime: convertedSchedule,
      contacts,
      description,
      languages,
      tags,
      photos,
      withPhotos: !isEditing,
    });

    if (errors?.length) {
      setModalText(`В следующих полях есть ошибки:\n\n${errors.join('\n\n')}`);
      setIsModalVisible(true);
      setModalState('error');
      return;
    }

    if (!isEditing) {
      const data = await sendRequest({
        request: Api.createOrganization,
        payload: formData,
        warnErrorText: 'while creating organization',
        setLoading,
      });

      setIsModalVisible(true);
      if (data?.status !== 'ok') {
        const errorText = data?.message || 'Не удалось установить соединение с сервером.';
        setModalText(`Возникла ошибка:\n\n${errorText}`);
        setModalState('error');
      } else {
        setModalText(`Организация "${name}" успешно была создана!`);
        setModalState('success');
      }
    } else {
      const data = await sendRequest({
        request: Api.editOrganization,
        payload: { payload, id: organizationId },
        warnErrorText: 'while editing organization',
        setLoading,
      });

      setIsModalVisible(true);
      if (data?.status !== 'ok') {
        const errorText = data?.message || 'Не удалось установить соединение с сервером.';
        setModalText(`Возникла ошибка:\n\n${errorText}`);
        setModalState('error');
      } else {
        setModalText(`Организация "${name}" успешно была редактирована!`);
        setModalState('success');
      }
    }
  };

  const onCloseModal = () => {
    if (modalState === 'error') {
      setIsModalVisible(false);
      setTimeout(() => {
        setModalText('');
        setModalState('');
      }, 400);
    } else if (modalState === 'success') {
      setIsModalVisible(false);
      navigate('/organizations/view');
    }
  };

  const getCategoriesAndSubCategoriesAtEditing = (selectedOrganization) => {
    let newCategory = {};
    const newSubCategories = [];

    if (selectedOrganization.category) {
      newCategory = categoriesFromStore.find(
        (i) => i._id === selectedOrganization.category._id,
      );

      if (
        newCategory.sub_categories
          && selectedOrganization.sub_categories.length
      ) {
        selectedOrganization.sub_categories.forEach((item) => {
          const tmp = newCategory.sub_categories.find((i) => i._id === item._id);
          if (tmp) {
            newSubCategories.push(tmp);
          }
        });
      }
    }
    return [newCategory, newSubCategories];
  };

  const getLanguagesAtEditing = (selectedOrganization) => {
    const newLanguages = [];

    if (selectedOrganization.languages?.length) {
      selectedOrganization.languages.forEach((item) => {
        const tmp = languagesFromStore.find((i) => i._id === item._id);
        if (tmp) {
          newLanguages.push(tmp);
        }
      });
    }
    return newLanguages;
  };

  const getSchedule = (selectedOrganization) => {
    const newSchedule = {};
    delete selectedOrganization.work_time.__v;
    delete selectedOrganization.work_time._id;

    Object.keys(selectedOrganization.work_time).forEach((key) => {
      const thisDayType = selectedOrganization.work_time[key];
      if (thisDayType.length === 11 && thisDayType.includes('-')) {
        // when type === hours
        const [start, end] = thisDayType.split('-');
        newSchedule[key] = { start, end, type: 'hours' };
      } else {
        newSchedule[key] = { start: '', end: '', type: thisDayType };
      }
    });

    return newSchedule;
  };

  const initData = async () => {
    const data = await sendRequest({
      request: Api.getOrganization,
      payload: { id: organizationId },
      warnErrorText: 'while getting single organization',
      setLoading: setGettingLoading,
    });

    if (data?.status !== 'ok') {
      setGettingError(typeof data.message === 'string' ? data.message : 'Ошибка');
    }

    const selectedOrganization = data.organization;
    setOwnerId(selectedOrganization.user._id);
    setName(selectedOrganization.name);
    setSpecialization(selectedOrganization.specialization);
    setAddress(selectedOrganization.address);
    setDescription(selectedOrganization.description || '');

    delete selectedOrganization.contacts.__v;
    delete selectedOrganization.contacts._id;
    setContacts(selectedOrganization.contacts);

    const [newCategory, newSubCategories] = getCategoriesAndSubCategoriesAtEditing(selectedOrganization);
    setCategory(newCategory);
    setTimeout(() => {
      setSubCategories(newSubCategories);
    }, 100);
    setCity(selectedOrganization.city);
    setTags(selectedOrganization.tags);
    setTimeout(() => {
      setCoordinates(selectedOrganization.coordinates);
    }, 300);

    const newLanguages = getLanguagesAtEditing(selectedOrganization);
    setLanguages(newLanguages);

    const newSchedule = getSchedule(selectedOrganization);
    setSchedule(newSchedule);
  };

  useEffect(() => {
    setSubCategories([]);
  }, [category]);

  useEffect(() => {
    dispatch(getCategoriesRequest());
    dispatch(getLanguagesRequest());
  }, []);

  useEffect(() => {
    if (isEditing && categoriesFromStore?.length && languagesFromStore?.length) {
      initData().then();
    }
  }, [categoriesFromStore, languagesFromStore]);

  return (
    <div className="add_organization">
      <PageTitle
        text={isEditing ? 'Редактировать организацию' : 'Добавить организацию'}
        RightComponent={(
          <div className="add_organization_button_group">
            <MyButton
              text="Отменить"
              onClick={onCancelClick}
              className="add_organization_button_group_cancel"
              secondary
            />
            {!gettingError && (
            <MyButton
              text="Сохранить"
              onClick={onSave}
              className="add_organization_button_group_save"
              loading={loading}
            />
            )}
          </div>
        )}
      />
      {gettingLoading
        ? <p className="add_organization_info_text">Загрузка</p>
        : gettingError
          ? <p className="add_organization_info_text">{gettingError}</p>
          : (
            <div className="add_organization_container">
              <SearchInput
                wrapperClassName="add_organization_container_form_input"
                placeholder="ID владельца"
                withLeftIcon={false}
                value={ownerId}
                onChange={setOwnerId}
                shouldValueConsiderAsFocus={false}
              />

              <SearchInput
                wrapperClassName="add_organization_container_form_input"
                placeholder="Название"
                withLeftIcon={false}
                value={name}
                onChange={setName}
                shouldValueConsiderAsFocus={false}
              />
              <SelectButton
                label="Категория"
                value={category?.name}
                setTempComponentState={setTempComponentState}
                componentStateToSet={tempComponentStates.categories}
                isActive={tempComponentState === tempComponentStates.categories}
              />
              {!!category?.sub_categories?.length && (
              <SelectButton
                label="Подкатегории"
                value={subCategoriesName}
                setTempComponentState={setTempComponentState}
                componentStateToSet={tempComponentStates.subCategories}
                isActive={tempComponentState === tempComponentStates.subCategories}
              />
              )}
              <SearchInput
                wrapperClassName="add_organization_container_form_input"
                placeholder="Специализация"
                withLeftIcon={false}
                value={specialization}
                onChange={setSpecialization}
                shouldValueConsiderAsFocus={false}
              />
              <SelectButton
                label="Город"
                value={city?.name}
                setTempComponentState={setTempComponentState}
                componentStateToSet={tempComponentStates.city}
                isActive={tempComponentState === tempComponentStates.city}
              />
              <SearchInput
                wrapperClassName="add_organization_container_form_input"
                placeholder="Адрес"
                withLeftIcon={false}
                value={address}
                onChange={setAddress}
                shouldValueConsiderAsFocus={false}
              />
              <MapView
                city={city}
                coordinates={coordinates}
                setCoordinates={setCoordinates}
              />
              <DaysSection
                setSchedule={setSchedule}
                schedule={schedule}
              />
              <ContactsSection
                contacts={contacts}
                setContacts={setContacts}
              />
              <SearchInput
                wrapperClassName="add_organization_container_descr"
                withLeftIcon={false}
                placeholder="Описание"
                value={description}
                onChange={setDescription}
                isLarge
                shouldValueConsiderAsFocus={false}
              />
              <SelectButton
                label="Языки"
                value={languagesName}
                setTempComponentState={setTempComponentState}
                componentStateToSet={tempComponentStates.languages}
                isActive={tempComponentState === tempComponentStates.languages}
              />
              <SelectButton
                label="Тэги"
                value={tagsName}
                setTempComponentState={setTempComponentState}
                componentStateToSet={tempComponentStates.tags}
                isActive={tempComponentState === tempComponentStates.tags}
              />
              {!isEditing && (
              <SelectButton
                label="Добавить фото"
                value={photos.length ? `${photos.length} фото` : ''}
                setTempComponentState={setTempComponentState}
                componentStateToSet={tempComponentStates.photos}
                isActive={tempComponentState === tempComponentStates.photos}
              />
              )}
            </div>
          )}

      {tempComponentState && (
      <div className="add_organization_temp_container">
        {tempComponentState === tempComponentStates.categories ? (
          <SelectCategory
            setValue={setCategory}
            setTempComponentState={setTempComponentState}
          />
        ) : tempComponentState === tempComponentStates.subCategories ? (
          <SelectSubCategories
            setValue={setSubCategories}
            setTempComponentState={setTempComponentState}

            selectedCategory={category}
            initValue={subCategories}
          />
        ) : tempComponentState === tempComponentStates.city ? (
          <SelectCity
            setValue={setCity}
            setTempComponentState={setTempComponentState}
          />
        ) : tempComponentState === tempComponentStates.languages ? (
          <SelectLanguages
            setValue={setLanguages}
            setTempComponentState={setTempComponentState}
            initValue={languages}
          />
        ) : tempComponentState === tempComponentStates.tags ? (
          <SelectTags
            setValue={setTags}
            setTempComponentState={setTempComponentState}
            initValue={tags}
          />
        ) : tempComponentState === tempComponentStates.photos ? (
          <SelectPhotos
            setValue={setPhotos}
            setTempComponentState={setTempComponentState}
            initValue={photos}
          />
        ) : null}
      </div>
      )}

      <MyModal isOpen={isModalVisible} closeFunction={onCloseModal}>
        <div className="add_organization_modal">
          <pre>{modalText}</pre>

          <MyButton
            className="add_organization_modal_button"
            text="Ок"
            onClick={onCloseModal}
          />
        </div>
      </MyModal>
    </div>

  );
}

export default AddOrganization;
