export const COLORS = {
  primary: '#3545DE',
  textPrimary: '#061F40',
  darkBlue: '#061F40',
  red: '#F44336',
  white: '#FFFFFF',
  background: '#F6F7F9',
  lightBlue: '#D7DAF8',
  textSecondary: '#7F8A99',

  border: '#E6E8EB',
  'primary-hover': '#C3C8F6',
};

export const USER_TYPES = {
  admin: 'admin',
  root: 'root',
  user: 'user',
};

export const REGEXP = {
  email: /\S+@\S+\.\S+/,
  imgSrc: /(?:\s+src="(.*?)"[^.]*?\/?>|&lt;\s+src="(.*?)"[^.]*?\/?&gt;)/gm,
  margin: /(?:\s+margin="(.*?)"[^.]*?\/?>|&lt;\s+margin="(.*?)"[^.]*?\/?&gt;)/gm,
};

export const HTML_TAGS = [{
  tagName: '<h1 />',
  copyText: '<h1>ЗАГОЛОВОК</h1>',
  label: 'Заголовок',
}, {
  tagName: '<h2 />',
  copyText: '<h2>ВТОРОЙ ЗАГОЛОВОК</h2>',
  label: 'Второй заголовок',
}, {
  tagName: '<h3 />',
  copyText: '<h3>ТРЕТИЙ ЗАГОЛОВОК</h3>',
  label: 'Третий заголовок',
}, {
  tagName: '<h4 />',
  copyText: '<h4>ПОДЗАГОЛОВОК</h4>',
  label: 'Подзаголовок',
}, {
  tagName: '<pre />',
  copyText: '<pre>\nТЕКСТ, СОХРАНЯЮЩИЙ СТРУКТУРУ\n</pre>',
  label: 'Текст',
}, {
  tagName: '<a />',
  copyText: '<a href="https://ВАША_ССЫЛКА">ТЕКСТ ССЫЛКИ</a>',
  label: 'Гиперссылка',
}, {
  tagName: '<ul />',
  copyText: '<ul>\n<li>ПЕРВЫЙ ЭЛЕМЕНТ СПИСКА</li>\n<li>ВТОРОЙ ЭЛЕМЕНТ СПИСКА</li>\n<li>ПОСЛЕДНИЙ ЭЛЕМЕНТ СПИСКА</li>\n</ul>',
  label: 'Список',
}, {
  tagName: '<li />',
  copyText: '<li>ЭЛЕМЕНТ СПИСКА</li>',
  label: 'Элемент списка',
  copyWithNewLine: false,
}, {
  tagName: '<sub />',
  copyText: '<sub margin="8"></sub>',
  label: 'Отступ',
}, {
  tagName: '<img />',
  label: 'Картинка',
  navigateTo: '/gallery',
}, {
  tagName: '<b />',
  copyText: '<b>ЖИРНЫЙ ТЕКСТ</b>',
  label: 'Bold',
  copyWithNewLine: false,
}];

export const PHOTOS_COUNT = {
  uploadAtOnce: 15,

  reviewMin: 0,
  reviewMax: 15,

  carouselMin: 5,
  carouselMax: 15,

  ownerMin: 0,
  ownerMax: 30,

  userToOneOrganizationMin: 0,
  userToOneOrganizationMax: 30,

  wrongInformationMin: 0,
  wrongInformationMax: 15,
};
