import React, { useEffect } from 'react';
import './css/index.scss';
import { useDispatch, useSelector } from 'react-redux';
import useInputState from '../../helpers/hooks/useInputState';
import MyButton from '../../components/MyButton';
import FormInput from '../../components/FormInput';
import { loginRequest } from '../../store/actions/account';
import Email from '../../assets/sidebarIcons/email.svg';
import EmailBlue from '../../assets/sidebarIcons/emailBlue.svg';
import EmailRed from '../../assets/sidebarIcons/emailRed.svg';
import Lock from '../../assets/loginIPageIcons/lock.svg';
import LockBlue from '../../assets/loginIPageIcons/lockBlue.svg';
import LockRed from '../../assets/loginIPageIcons/lockRed.svg';
import checkIfErrorExists from '../../helpers/checkIfErrorExists';
import ERRORS, { ERROR_TYPES } from '../../helpers/errors';
import PasswordServices from '../../helpers/PasswordServices';

function LoginPage() {
  const dispatch = useDispatch();
  const { loginLoading, loginError } = useSelector((store) => store.account);

  const [email, setEmail, emailError, setEmailError] = useInputState();
  const [password, setPassword, passwordError, setPasswordError] = useInputState();

  const onSubmit = (ev) => {
    ev.preventDefault();
    dispatch(loginRequest({ email, password: PasswordServices.encryptPassword(password) }));
  };

  useEffect(() => {
    if (checkIfErrorExists(loginError, ERROR_TYPES.email)) {
      setEmailError(ERRORS[loginError].text);
    } else if (checkIfErrorExists(loginError, ERROR_TYPES.password)) {
      setPasswordError(ERRORS[loginError].text);
    }
  }, [loginError]);

  return (
    <div className="login_wrapper">
      <div className="registration_form_div">
        <form onSubmit={onSubmit}>
          <header>
            <h1 className="form__title">nomad</h1>
          </header>
          <FormInput
            placeholder="E mail"
            autoComplete="on"
            value={email}
            onChange={setEmail}
            error={emailError}
            svgPath={Email}
            svgErrorPath={EmailRed}
            svgActivePath={EmailBlue}
          />
          <FormInput
            placeholder="Пароль"
            autoComplete="on"
            type="password"
            value={password}
            onChange={setPassword}
            error={passwordError}
            svgPath={Lock}
            svgErrorPath={LockRed}
            svgActivePath={LockBlue}
          />
          <MyButton
            onClick={onSubmit}
            type="submit"
            text="Войти"
            className="login_button"
            loading={loginLoading}
          />
          <input type="submit" className="hidden_input" />
        </form>
      </div>
    </div>
  );
}

export default LoginPage;
