import React, { useEffect, useRef } from 'react';
import './css/index.scss';
import getModifiedHtml from '../../../../helpers/getModifiedHtml';

function ArticlesEditBlock({ innerHtml, setInnerHtml }) {
  const visibleRef = useRef(null);
  const editableRef = useRef(null);

  useEffect(() => {
    if (!visibleRef.current) return;

    getModifiedHtml(innerHtml, visibleRef);
  }, [visibleRef]);

  useEffect(() => {
    if (!editableRef.current) return;
    editableRef.current.style.height = `${editableRef.current.scrollHeight}px`;

    const listener = () => {
      editableRef.current.style.height = `${editableRef.current.scrollHeight}px`;
    };
    editableRef.current.addEventListener('input', listener);
  }, [editableRef]);

  const onChange = (ev) => {
    getModifiedHtml(ev.target.value, visibleRef);
    setInnerHtml(ev);
  };

  return (
    <div className="articles_edit_block_wrapper">
      <pre
        ref={visibleRef}
        className="articles_edit_input non_editable"
      />
      <textarea
        ref={editableRef}
        className="articles_edit_input editable"
        onChange={onChange}
        value={innerHtml}
        spellCheck={false}
      />
    </div>
  );
}

export default ArticlesEditBlock;
