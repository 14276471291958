import getWordForm from './getWordForm';

function getNameToShowOfMany({
  list, field = 'name', words, firstWord = 'Выбрано',
}) {
  if (!list?.length) {
    return '';
  } if (list.length <= 3) {
    return (field === 'self' ? list : list.map((i) => i[field])).join(' / ');
  }
  return `${firstWord} ${list.length}${words ? ` ${getWordForm(list.length, words)}` : ''}`.trim();
}

export default getNameToShowOfMany;
