import ERRORS from './errors';

function checkIfErrorExists(message, type) {
  if (message && ERRORS[message] && ERRORS[message].types.includes(type)) {
    return true;
  }

  return false;
}

export default checkIfErrorExists;
