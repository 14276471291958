export const COUNTRY_INFO_WIDTH = [42, 25, 25, 5]; // margin right

export const COUNTRY_INFO_HEADER_COLUMNS = [
  { text: 'Город', width: COUNTRY_INFO_WIDTH[0] },
  { text: 'Lat', width: COUNTRY_INFO_WIDTH[1] },
  { text: 'Lon', width: COUNTRY_INFO_WIDTH[2] },
  { text: '', width: COUNTRY_INFO_WIDTH[3] },
];

export const DELETING_MODAL_STATES = {
  country: 'country',
  city: 'city',
};
