import React from 'react';
import './css/index.scss';

function PageTitle({ text, RightComponent }) {
  return (
    <div className={`page_title ${RightComponent && 'wide'}`}>
      <p className="page_title_text">{text}</p>
      {RightComponent}
    </div>
  );
}

export default PageTitle;
