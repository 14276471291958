import {
  ADD_COUNTRY,
  DELETE_COUNTRY, GET_CATEGORIES_SUCCESS,
  GET_COUNTRIES_SUCCESS,
  GET_INFO_TABLE_SUCCESS, GET_LANGUAGES_SUCCESS,
  SET_INFO_TABLE,
  UPDATE_COUNTRY,
  UPDATE_INFO_TABLE_BLOCK,
} from '../actions/staticData';

const initialState = {
  countries: [],
  infoTable: [],
  categories: [],
  languages: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_COUNTRIES_SUCCESS: {
      return {
        ...state,
        countries: action.payload,
      };
    }

    case GET_LANGUAGES_SUCCESS: {
      return {
        ...state,
        languages: action.payload,
      };
    }

    case GET_INFO_TABLE_SUCCESS: {
      return {
        ...state,
        infoTable: action.payload,
      };
    }

    case GET_CATEGORIES_SUCCESS: {
      return {
        ...state,
        categories: action.payload,
      };
    }

    case SET_INFO_TABLE: {
      return {
        ...state,
        infoTable: action.payload,
      };
    }

    case UPDATE_INFO_TABLE_BLOCK: {
      let thisBlock = state.infoTable.find((item) => item._id === action.payload._id);
      thisBlock = Object.assign(thisBlock, action.payload);
      return {
        ...state,
        infoTable: state.infoTable,
      };
    }

    case ADD_COUNTRY: {
      return {
        ...state,
        countries: [action.payload, ...state.countries],
      };
    }

    case UPDATE_COUNTRY: {
      const newCountries = state.countries.map(
        (item) => (item._id === action.payload._id ? Object.assign(item, action.payload) : item),
      );
      return {
        ...state,
        countries: newCountries,
      };
    }

    case DELETE_COUNTRY: {
      const filteredCountries = state.countries.filter((item) => (item._id !== action.payload._id));
      return {
        ...state,
        countries: filteredCountries,
      };
    }

    default: {
      return state;
    }
  }
}
