import React, { useEffect, useState } from 'react';
import './css/index.scss';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useInputState from '../../helpers/hooks/useInputState';
import PageTitle from '../../components/PageTitle';
import MyButton from '../../components/MyButton';
import PhotosPart from './components/PhotosPart';
import sendRequest from '../../helpers/sendRequest';
import Api from '../../Api';

function EditOrganizationPhotos() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const organizationId = searchParams.get('id');

  const [gettingError, setGettingError] = useState('');

  const [ownerId, setOwnerId] = useInputState();
  const [savedPhotos, setSavedPhotos] = useState([]);

  const onCancelClick = () => {
    navigate('/organizations/view');
  };

  const initData = async () => {
    const data = await sendRequest({
      request: Api.getOrganization,
      payload: { id: organizationId },
      warnErrorText: 'while getting single organization',
    });

    if (data?.status !== 'ok') {
      setGettingError('Ошибка');
      return;
    }

    const selectedOrganization = data.organization;
    setOwnerId(selectedOrganization.user._id);
    setSavedPhotos(selectedOrganization.carousel_photos);
  };

  useEffect(() => {
    initData().then();
  }, []);

  return (
    <div className="edit_organization_photos">
      <PageTitle
        text="Редактировать фото"
        RightComponent={(
          <div className="edit_organization_photos_button_group">
            <MyButton
              text="Назад"
              onClick={onCancelClick}
              className="edit_organization_photos_button_group_cancel"
              secondary
            />
          </div>
        )}
      />
      {gettingError
        ? <p className="edit_organization_photos_info_text">{gettingError}</p>
        : (
          <div className="edit_organization_photos_container">
            <PhotosPart
              savedPhotos={savedPhotos}
              ownerId={ownerId}
              initData={initData}
              organizationId={organizationId}
            />
          </div>
        )}
    </div>

  );
}

export default EditOrganizationPhotos;
