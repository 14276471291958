import React, { useEffect } from 'react';
import {
  APIProvider, Map, Marker, useMap,
} from '@vis.gl/react-google-maps';

const API_KEY = 'AIzaSyA96_9BRgou4lb0wXy_4-ZLeGadGVPjWe4';

function MapComponent({ city, coordinates, setCoordinates }) {
  const map = useMap();

  const onMapClick = (ev) => {
    setCoordinates({
      lat: ev.detail.latLng.lat.toString(),
      lon: ev.detail.latLng.lng.toString(),
      is_special_point_on_map: Boolean(ev.detail.placeId),
    });

    map.setZoom(15);
    map.panTo(ev.detail.latLng);
  };

  useEffect(() => {
    if (!map) return;
    if (city?.coordinates) {
      // map.setZoom(10);
      map.panTo({ lat: +city.coordinates.lat, lng: +city.coordinates.lon });
    }
  }, [map, city]);

  useEffect(() => {
    if (!map) return;
    if (coordinates?.lat) {
      map.setZoom(15);
      map.panTo({ lat: +coordinates.lat, lng: +coordinates.lon });
    }
  }, [coordinates]);

  return (
    <Map
      style={{ width: '100%', height: '100%' }}
      defaultZoom={1}
      defaultCenter={{ lat: 0, lng: 0 }}
      gestureHandling="greedy"
      disableDefaultUI
      onClick={onMapClick}
    >
      {!!coordinates?.lat && (
        <Marker position={{ lat: +coordinates.lat, lng: +coordinates.lon }} />
      )}
    </Map>
  );
}

function MapView(props) {
  return (
    <div className="map_wrapper" style={{ width: '100%', height: '260px', borderRadius: '12px' }}>
      <APIProvider apiKey={API_KEY}>
        <MapComponent {...props} />
      </APIProvider>
    </div>
  );
}

export default MapView;
