import { call, put, takeLatest } from 'redux-saga/effects';
import Api from '../../Api';
import { GET_ADMINS_FAIL, GET_ADMINS_REQUEST, GET_ADMINS_SUCCESS } from '../actions/admins';

export default function* watcher() {
  yield takeLatest(GET_ADMINS_REQUEST, getAdmins);
}

function* getAdmins(action) {
  try {
    const { data } = yield call(Api.getAdmins, action.payload);

    yield put({
      type: GET_ADMINS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.warn(error, 'while getting admins');

    let errorMessage = 'Unknown error';

    if (error.response) {
      errorMessage = error.response.data.message || 'Unknown server error';
    } else if (error.request) {
      errorMessage = 'No response from the server';
    } else {
      errorMessage = error.message || 'Unknown error';
    }

    yield put({
      type: GET_ADMINS_FAIL,
      payload: errorMessage,
    });
  }
}
