import {
  GET_ORGANIZATIONS_FAIL,
  GET_ORGANIZATIONS_REQUEST,
  GET_ORGANIZATIONS_SUCCESS,
  UPDATE_ORGANIZATION,
} from '../actions/organizations';

const initialState = {
  organizations: [],
  organizationsTotal: 0,
  organizationsLoading: false,
  organizationsError: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_ORGANIZATIONS_REQUEST: {
      return {
        ...state,
        organizationsLoading: true,
        organizationsError: false,
      };
    }
    case GET_ORGANIZATIONS_SUCCESS: {
      return {
        ...state,
        organizations: action.payload.organizations,
        organizationsTotal: action.payload.total,
        organizationsLoading: false,
        organizationsError: false,
      };
    }
    case GET_ORGANIZATIONS_FAIL: {
      return {
        ...state,
        organizationsLoading: false,
        organizationsError: true,
      };
    }
    case UPDATE_ORGANIZATION: {
      const newOrganizations = state.organizations.map(
        (item) => (item._id === action.payload._id ? Object.assign(item, action.payload) : item),
      );
      return {
        ...state,
        organizationsLoading: false,
        organizationsError: true,
        organizations: newOrganizations,
      };
    }

    default: {
      return state;
    }
  }
}
