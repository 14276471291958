export const ERROR_TYPES = {
  code: 'code',
  email: 'email',
  password: 'password',
  name: 'name',
  info: 'info',
};

const ERRORS = {
  // login
  'There is no such admin': {
    types: [ERROR_TYPES.email],
    text: 'Указанный email не зарегистрирован',
  },
  'Wrong password': {
    types: [ERROR_TYPES.password],
    text: 'Неверный пароль',
  },
  // countries
  'Name length minimum 2': {
    types: [ERROR_TYPES.name],
    text: 'Минимум 2 символа',
  },
  'There are organizations in this country': {
    types: [ERROR_TYPES.info],
    text: 'Вы не можете удалить эту страну, так как в ней есть организации',
  },
  'There are organizations in this city': {
    types: [ERROR_TYPES.info],
    text: 'Вы не можете удалить этот город, так как в нём есть организации',
  },
};

export default ERRORS;
