import _ from 'lodash';

export default async ({
  request,
  setLoading,
  setError,
  warnErrorText,
  payload,
  errorPlaceholder = 'Неизвестная ошибка',
  errorPath = 'response.data',
}) => {
  try {
    if (setLoading) {
      setLoading(true);
    }
    const { data } = await request(payload);
    return data;
  } catch (e) {
    // console.warn(JSON.stringify(e), warnErrorText);
    console.warn(e?.response?.data, warnErrorText);
    if (setError) {
      setError(_.get(e, errorPath, errorPlaceholder));
    }
    return e?.response?.data;
  } finally {
    if (setLoading) {
      setLoading(false);
    }
  }
};
