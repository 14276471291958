import React from 'react';
import './css/index.scss';

function MyToggle({ value, onClick, isDisabled }) {
  const handleClick = (ev) => {
    ev.stopPropagation();
    if (isDisabled) return;
    onClick();
  };

  return (
    <div
      onClick={handleClick}
      className={`toggle_wrapper ${isDisabled && 'toggle_disabled'} ${value && !isDisabled && 'toggle_selected'}`}
    >
      <div className={`toggle_inner ${value && !isDisabled && 'toggle_inner_selected'}`} />
    </div>
  );
}

export default MyToggle;
