import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import MyButton from '../../../components/MyButton';
import CloseSmallIcon from '../../../assets/formIcons/closeSmall.svg';
import SearchInput from '../../../components/SearchInput';
import useInputState from '../../../helpers/hooks/useInputState';
import AddPhotosIcon from '../../../assets/formIcons/addPhotos.svg';
import { GALLERY_MODAL_STATES } from '../../Gallery/constants';
import getFormData from '../../../helpers/getFormData';
import sendRequest from '../../../helpers/sendRequest';
import Api from '../../../Api';
import DeletePhotoIcon from '../../../assets/formIcons/deletePhoto.svg';
import CopyPhotoIcon from '../../../assets/formIcons/copyPhoto.svg';

function SelectPhotos({
  setValue, setTempComponentState, initValue = [],
}) {
  const selectPhotosInput = useRef();

  const [selected, setSelected] = useState(initValue);

  const onAddClick = () => {
    if (typeof selectPhotosInput.current?.click === 'function') {
      selectPhotosInput.current.click();
    }
  };

  const onPhotosSelect = async (ev) => {
    const { files: filesList } = ev.target;
    const files = Array.from(filesList);
    if (!filesList.length) return;

    const convertedFiles = files.map((item) => ({
      file: item,
      id: Math.random(),
      uri: URL.createObjectURL(item),
    }));

    setSelected((prev) => [...convertedFiles, ...prev].slice(0, 15));
  };

  const onDeleteClick = (photo) => () => {
    setSelected((prev) => prev.filter((item) => item.id !== photo.id));
  };

  const handleSubmit = () => {
    setValue(selected);
    setTempComponentState(null);
  };

  return (
    <div className="temp_container">
      <p className="temp_container_title">Фото на главной карусели</p>
      <div className="temp_container_scroll with_button">
        <p className="temp_container_description">Добавьте от 5 до 15 фото, которые будут отображаться на карточке и странице этой организации</p>
        <MyButton
          text="Добавить"
          svgPath={AddPhotosIcon}
          onClick={onAddClick}
          className="temp_container_add_btn"
        />
        <input
          ref={selectPhotosInput}
          type="file"
          multiple
          accept="image/png, image/jpeg"
          className="temp_container_select_photos"
          onChange={onPhotosSelect}
        />

        <div className="temp_container_photos">
          {selected.length ? (
            selected.map((photo) => (
              <div
                key={photo.id}
                className="temp_container_photos_item"
              >
                <img
                  src={photo.uri}
                  alt=""
                  className="temp_container_photos_item_photo"
                />

                <div className="temp_container_photos_item_overlay">
                  <div
                    className="temp_container_photos_item_overlay_icon"
                    onClick={onDeleteClick(photo)}
                  >
                    <img src={DeletePhotoIcon} alt="" />
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p className="temp_container_photos_no_photo">Нет выбранных фото</p>
          )}
        </div>
      </div>

      <MyButton onClick={handleSubmit} text="Ок" className="temp_container_button" />
    </div>
  );
}

export default SelectPhotos;
