import { call, put, takeLatest } from 'redux-saga/effects';
import Api from '../../Api';
import {
  GET_COMPLAINTS_COUNT_REQUEST,
  GET_COMPLAINTS_REQUEST, getComplaintsCountFail, getComplaintsCountSuccess,
  getComplaintsFail,
  getComplaintsSuccess,
} from '../actions/reports';

function* getComplaints(action) {
  try {
    const { systemCountry, selectedFilter } = action.payload;
    const { data } = yield call(Api.getComplaints, {
      params: { country_id: systemCountry._id, type: selectedFilter.id },
    });

    if (data?.status === 'ok') {
      yield put(getComplaintsSuccess(data));
    } else {
      yield put(getComplaintsFail('Error fetching complaints'));
    }
  } catch (error) {
    console.warn(error, 'while getting complaints');
    yield put(getComplaintsFail('Error fetching complaints'));
  }
}

function* getComplaintsCount(action) {
  try {
    const { systemCountry } = action.payload;
    const { data } = yield call(Api.getComplaintsCount, {
      params: { country_id: systemCountry._id },
    });

    if (data?.status === 'ok') {
      yield put(getComplaintsCountSuccess(data));
    } else {
      yield put(getComplaintsCountFail('Error fetching complaints count'));
    }
  } catch (error) {
    console.warn(error, 'while getting complaints count');
    yield put(getComplaintsCountFail('Error fetching complaints count'));
  }
}

function* reportsSaga() {
  yield takeLatest(GET_COMPLAINTS_REQUEST, getComplaints);
  yield takeLatest(GET_COMPLAINTS_COUNT_REQUEST, getComplaintsCount);
}

export default reportsSaga;
