import React, { useState } from 'react';
import './css/index.scss';
import { useDispatch } from 'react-redux';
import MyModal from '../../../../components/MyModal';
import { DELETING_MODAL_STATES } from '../../constants';
import MyButton from '../../../../components/MyButton';
import sendRequest from '../../../../helpers/sendRequest';
import Api from '../../../../Api';
import { deleteCountryFromStorage } from '../../../../store/actions/staticData';
import checkIfErrorExists from '../../../../helpers/checkIfErrorExists';
import ERRORS, { ERROR_TYPES } from '../../../../helpers/errors';

function DeletingModal({
  isVisible,
  setIsVisible,
  modalState,
  selectedCity = {},
  setSelectedCity,
  selectedCountry = {},
  setSelectedCountry,
  countries,
  setCities,
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const closeModal = () => {
    if (loading) {
      return;
    }
    setIsVisible(false);
    setTimeout(() => {
      setSelectedCity({});
      setError('');
    }, 400);
  };

  const deleteCity = async () => {
    const data = await sendRequest({
      request: Api.deleteCity,
      payload: selectedCity._id,
      warnErrorText: 'while deleting city',
      setLoading,
    });

    if (data?.status === 'ok') {
      setCities((prev) => prev.filter((item) => item._id !== selectedCity._id));
      closeModal();
    }
  };
  const deleteCountry = async () => {
    const data = await sendRequest({
      request: Api.deleteCountry,
      payload: selectedCountry._id,
      warnErrorText: 'while deleting country',
      setLoading,
    });

    if (data?.status === 'ok') {
      const index = countries[0]._id === selectedCountry._id ? 1 : 0;
      setSelectedCountry(countries[index]);

      dispatch(deleteCountryFromStorage(selectedCountry));
      closeModal();
      return;
    }

    if (checkIfErrorExists(data.message, ERROR_TYPES.info)) {
      setError(ERRORS[data.message].text);
    }
  };

  const onDeleteClick = async () => {
    if (modalState === DELETING_MODAL_STATES.country) {
      await deleteCountry();
    } else if (modalState === DELETING_MODAL_STATES.city) {
      await deleteCity();
    }
  };

  return (
    <MyModal isOpen={isVisible} closeFunction={closeModal}>
      <div className="countries_delete_modal">
        <p className="modal_title">Удалить?</p>

        <p className="modal_info_name">
          {modalState === DELETING_MODAL_STATES.country ? selectedCountry.name : selectedCity.name}
        </p>

        {error ? (
          <p className="modal_error">{error}</p>
        ) : (
          <p className="modal_info">
            Вы уверены что хотите удалить
            {modalState === DELETING_MODAL_STATES.country ? ' эту страну' : ' этот город'}
            ? Отменить данное действие будет невозможно.
          </p>
        )}

        <div className="modal_buttons_row">
          <MyButton className="modal_button" text="Отмена" secondary onClick={closeModal} />
          <MyButton
            className="modal_button"
            text="Удалить"
            loading={loading}
            onClick={onDeleteClick}
            disabled={error}
          />
        </div>
      </div>
    </MyModal>
  );
}

export default DeletingModal;
