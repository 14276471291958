import React from 'react';
import './css/index.scss';
import { useDispatch, useSelector } from 'react-redux';
import DropdownIcon from '../../assets/formIcons/infoDropdown.svg';
import { setInfoTable, updateInfoTableBlock } from '../../store/actions/staticData';
import { infoTableIcons } from '../../helpers/infoTableIcon';
import MyToggle from '../MyToggle';

function InfoTable({
  withToggle, onClick, onToggleClick, selectedInfo,
}) {
  const dispatch = useDispatch();
  const { infoTable } = useSelector((store) => store.staticData);

  const onTitleClick = (clickedBlock) => {
    clickedBlock.isClosed = !clickedBlock.isClosed;
    dispatch(updateInfoTableBlock(clickedBlock));
  };

  const handleClick = (item) => () => {
    onClick(item);
  };

  return (
    <div className="info_table">
      {infoTable.map((block) => (

        // block._id === 'emergency_services' ? null : (
        (
          <div
            className={`info_block ${block.isClosed && 'is_closed'}`}
            key={block._id}
          >
            <div
              className="info_block_title"
              onClick={() => onTitleClick(block)}
            >
              {block.name}
              <img
                className="info_dropdown_img"
                src={DropdownIcon}
                alt=""
              />
            </div>
            <div
              className={`info_items_list ${withToggle && 'wide'}`}
              style={{ maxHeight: `${60 * block.items.length + 20}px`, height: `${60 * block.items.length + 20}px` }}
            >
              <div className="info_items_list_inner">
                {block.items.map((item) => (
                  <div
                    key={item._id}
                    onClick={handleClick(item)}
                    className={`info_item ${!item.inner_html && 'dashed'} ${selectedInfo._id === item._id && 'selected'}`}
                  >
                    <img
                      className={`info_item_icon ${!item.inner_html.length ? 'disabled_icon' : ''}`}
                      src={infoTableIcons[block.name] && infoTableIcons[block.name][item.name]}
                      alt=""
                    />

                    <p className="info_item_name">{item.name}</p>
                    {withToggle && (
                      <div className="info_item_toggle" onClick={(ev) => ev.stopPropagation()}>
                        <MyToggle
                          value={item.is_visible}
                          onClick={() => onToggleClick(item)}
                          isDisabled={!item.inner_html}
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )
      ))}
    </div>
  );
}

export default InfoTable;
