export const ADMIN_WIDTHS = [12, 19, 19, 19, 22, 3]; // margin right

export const ADMIN_HEADER_COLUMNS = [
  { text: 'Страны', width: ADMIN_WIDTHS[0] },
  { text: 'Имя', width: ADMIN_WIDTHS[1] },
  { text: 'Email', width: ADMIN_WIDTHS[2] },
  { text: 'Пароль', width: ADMIN_WIDTHS[3] },
  { text: 'ID администратора', width: ADMIN_WIDTHS[4] },
  { text: '', width: ADMIN_WIDTHS[5] },
];
export const ADMIN_MODAL_STATES = {
  delete: 'delete',

  first: 'first',
  selectCountries: 'selectCountries',
  confirm: 'confirm',
};
