import React from 'react';
import './css/index.scss';
import Api from '../../../../Api';

function CountryItem({
  country, onClick, isSelected,
}) {
  return (
    <li className={`country_item_wrapper ${isSelected && 'country_item_wrapper_selected'}`} onClick={onClick}>
      {country.flag_url ? <img src={Api.url + country.flag_url} alt="" /> : <div className="img_placeholder" />}
      <p>{country.name}</p>
    </li>
  );
}

export default CountryItem;
